const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`;
const inboxUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`;
const profileUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`;
const marketingUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`;
const calendarUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar`
const triggerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/trigger`
const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const developerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/developer`
const backendeUrlV1 = `${process.env.REACT_APP_JAVAEND_URL}/v1`
const dashboardUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/dashboard`
const formBackendServer17 = `${process.env.REACT_APP_FORM_BACKEND_17_SERVER}/v1`;
const formApi17 = `${process.env.REACT_APP_FORM_API_17}/v1`
const bulkEmailBackendApi = `${process.env.REACT_APP_BULK_EMAIL_BACKEND_SERVER}/api`
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`
const dealModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const insuranceOnboardUrl = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1/onboard`

const Api = {
    signupPageData: `${rootUrl}/signup/data`,
    validateRegistration: `${rootUrl}/signup/validation`,
    signup: `${rootUrl}/signup`,
    signupv2: `${rootUrl}/signupv2`,
    verifyOtp: `${rootUrl}/user-otp-verify`,
    resendOtpFromRegistrationPageScreen: `${rootUrl}/resend-otp-from-registration-page`,
    fetchLoginPageData: `${rootUrl}/login/data`,
    fetchPrivacyPolicy: `${rootUrl}/privacy-policy/data`,
    fetchTermsAndConditions: `${rootUrl}/terms-and-conditions/data`,
    resendOtpFromLoginScreen: `${rootUrl}/resend-otp-from-login-screen`,
    login: `${rootUrl}/authenticate`,
    loginOtpRequest: `${rootUrl}/user-otp-check`,
    forceLogin: `${rootUrl}/authenticate/force`,
    forgetPassword: `${rootUrl}/password/reset/request`,
    resetPassword: `${rootUrl}/password/reset`,
    fetchAccountData: `${rootUrl}/account-data`,
    fetchDirectAccountData: `${rootUrl}/direct-account-data`,
    getCurrentCredit: `${rootUrl}/user/current-credit`,
    dialerToken: `${rootUrl}/dialer/token`,
    getCodeSnippet: `${rootUrl}/code-snippet`,
    getContact: `${rootUrl}/contacts/recent/list`,
    getContactWithpagination: `${rootUrl}/contacts/recent/list-pagination`,
    getVirtualNumber: `${inboxUrl}/virtual-number-list`,
    getVirtualNumberFromTenant: `${tenantBackendV1}/virtual-number/get-user-virtual-number`,
    callHangUp: `${rootUrl}/call/participant/remove`,
    callCompleteByCallSid: `${rootUrl}/complete/incoming-call`,
    callCancelByCallSid: `${rootUrl}/cancel/incoming-call`,
    holdCurrentCall: `${rootUrl}/call/hold`,
    addParticipant: `${rootUrl}/call/participant/add`,
    unHoldParticipant: `${rootUrl}/call/unHold`,
    fetchOnboardData: `${rootUrl}/onboard-details`,
    onboardGoToStep: `${rootUrl}/onboard-go-to-step`,
    onboardGuideCompleted: `${rootUrl}/onboard-complete`,
    virtualSearchNumberList: `${profileUrl}/phone-settings/virtual-number-search-list`,
    buyVirtualNumber: `${profileUrl}/phone-settings/virtual-number-buy-submit`,
    getCanSpamAddress: `${profileUrl}/email-settings/get-can-spam-address`,
    saveCanSpamAddress: `${profileUrl}/email-settings/save-can-spam-address`,
    updateTimezone: `${profileUrl}/update-timezone`,
    saveEmailProvider: `${profileUrl}/email-settings/providers/save`,
    siteNotifications: `${rootUrl}/site-notifications`,
    leadNotifications: `${rootUrl}/lead-notifications`,
    markAllAsSeen: `${profileUrl}/mark-as-all-seen`,
    leadMarkAllAsSeen: `${profileUrl}/lead-mark-as-all-seen`,
    closeNotification: `${profileUrl}/close-site-notification`,
    closeLeadNotification: `${profileUrl}/change-lead-notification-status`,
    notificationCount: `${profileUrl}/get-notification-count`,
    viewUserMapFeatureApi: `${profileUrl}/mys/view-user-map-feature`,
    loadVideoData: `${marketingUrl}/video-email-data`,
    replyVideoEmail: `${marketingUrl}/reply-video-email`,
    createNewCampaign: `${marketingUrl}/campaign/create`,
    getTeamUsers: `${calendarUrl}/get-team-users`,
    getCalendarWidgets: `${calendarUrl}/get-calendar-widgets`,
    getCalendarWidgetDetails: `${calendarUrl}/get-calendar-widget-details`,
    getContacts: `${calendarUrl}/search-contacts`,
    saveActivityFollowup: `${calendarUrl}/save-activity`,
    searchContacts: `${rootUrl}/search-contacts`,
    searchContactsNew: `${rootUrl}/search-contacts-new`,
    customFields: `${rootUrl}/get-custom-fields`,
    getCopyTriggerSetting: `${triggerUrl}/get-shared-setting`,
    duplicateSharedSetting: `${triggerUrl}/duplicate-shared-setting`,
    getCopyCampaignSetting: `${marketingUrl}/get-shared-setting`,
    duplicateSharedCampaign: `${marketingUrl}/duplicate-shared-campaign`,
    getCopyContactSetting: `${contactUrl}/get-shared-setting`,
    duplicateSharedContact: `${contactUrl}/duplicate-shared-setting`,
    redirectNylasHosted: `${profileUrl}/redirect-nylas-hosted`,
    testEmailSend: `${marketingUrl}/campaign/send-test-email`,
    unsubscribePublicContact: `${rootUrl}/unsubscribe/campaign/contact`,
    connectNylas: `${profileUrl}/email-settings/nylas-connect-url`,


    /*Auto dialer api start*/
    getDialContactList: `${contactUrl}/auto-dialer/contacts`,
    dialerStop: `${contactUrl}/auto-dialer/stop`,
    setupForInitiatingCall: `${contactUrl}/auto-dialer/call/contact/setup`,
    contactMarkAsImportant: `${inboxUrl}/mark-as-important`,
    archiveContact: `${inboxUrl}/contact/archive`,
    terminateCall: `${contactUrl}/auto-dialer/call/terminate`,
    /*Auto dialer api end*/

    /* notifications */
    markAllNotificationsAsClosed: `${profileUrl}/mark-as-close-all-notifications`,
    updateSiteNotificationStatus: `${profileUrl}/update-site-notification-status`,
    updateLeadNotificationStatus: `${profileUrl}/update-lead-notification-status`,

    //Error Notification
    sendErrorNotification: `${developerUrl}/send-error-email`,

    //Searc Contact
    searchContactFromElastic: `${backendeUrlV1}/contacts/get-elastic-contacts`,

    getAgencyTwilioSurCharge: `${rootUrl}/agency/twilio/sur/charge`,

    getCustomFieldAndpersonalizedFieldGlobal: `${rootUrl}/user-custom-personalized-fields`,

    getDialerSettings: `${rootUrl}/get-user-dialing-setting`,

    fetchFormList: `${marketingUrl}/get-form-list`,

    getUserSettingData: `${rootUrl}/get-user-setting`,

    getChatConversation: `${rootUrl}/get-chat-conversation`,

    setupZenDirect: `${rootUrl}/third-party-credential/setup-zendirect`,

    submitTwilioMigrationInfo: `${dashboardUrl}/create-twilio-standard-profile`,

    getTwilioCampaignUseCase: `${dashboardUrl}/get-twilio-campaign-use-cases`,

    createCampaignUseCaseV2: `${dashboardUrl}/create-campaign-use-case-registration`,

    createCampaignUseCaseUpdateBrandV2: `${dashboardUrl}/create-campaign-use-case-registration-for-update-brand`,

    requestForSoleProprietorRegistration: `${dashboardUrl}/request-for-sole-proprietor-registration`,

    closeA2pModal: `${dashboardUrl}/close-a2p-modal`,

    hideA2pPopupPermanently: `${dashboardUrl}/hide-a2p-popup-parmanently`,

    checkUserA2pPopupRequired: `${dashboardUrl}/check-user-a2p-popup-required`,

    getUserCampaignRegistrationStatus: `${rootUrl}/get-user-campaign-registration-status`,

    form10DlcLink: `${formBackendServer17}/forms/10dlc-link`,

    agencyApiDomain: `${rootUrl}/agency/get-api-domain`,

    tiktokAuthRequest: `${rootUrl}/tiktokAuthRequest`,

    detailsNew: `${contactUrl}/details-new`,

    subUserListForContactDropdown: `${contactUrl}/sub-users-optimized`,
    changeLeadOwner: `${contactUrl}/change/lead-owner`,
    receiveBulkContactPermissionRequest: `${contactUrl}/contact-permission/bulk-permission-request`,
    updateContactLeadTypeApi: `${contactUrl}/update-contact-lead-type`,
    getZipCodeByPlace: `${contactUrl}/get-google-map-resource`,
    updateContactDetailsV2: `${contactUrl}/update-contact-details-v2`,

    createAdditionalContactApi: `${contactUrl}/additional-contacts/create`,
    getAllAdditionalContactApi: `${contactUrl}/additional-contacts`,
    updateAdditionalContactApi: `${contactUrl}/additional-contacts/update`,
    deleteAdditionalContactApi: `${contactUrl}/additional-contacts/delete`,
    makeNumberDefaultAPI: `${contactUrl}/additional-contacts/make-default`,
    updateContactCustomFieldV2: `${contactUrl}/update-contact-custom-field-v2`,

    makeDefaultContact: `${contactUrl}/additional-contacts/make-default`,

    deleteAdditionalContactNumberApi: `${contactUrl}/additional-contacts/number/delete`,
    deleteAdditionalContactEmailApi: `${contactUrl}/additional-contacts/email/delete`,

    deleteContactNumberApi: `${contactUrl}/number/delete`,
    deleteContactEmailApi: `${contactUrl}/email/delete`,

    makeDefaultAdditionalContactNumberApi: `${contactUrl}/additional-contacts/number/make-default`,
    makeDefaultAdditionalContactEmailApi: `${contactUrl}/additional-contacts/email/make-default`,

    deleteCleanContacts: `${contactUrl}/delete-clean-contacts`,

    updateImportantDates: `${contactUrl}/update-important-dates`,
    updateImportantLinks: `${contactUrl}/update-important-links`,
    updateContactInfo: `${contactUrl}/update-contact-info`,
    /* Email Type List Api */
    emailTypeListApi: `${contactUrl}/get/contact/email/relations`,

    /* Email Type List Api */
    addExtraEmailApi: `${contactUrl}/add/contact/extra/email`,

    /* Extra Email List Api */
    extraEmailListApi: `${contactUrl}/get/contact/extra/email`,

    /* Edit Extra Email List Api */
    editExtraEmailApi: `${contactUrl}/update/contact/extra/email`,

    /* Remove Extra Email */
    removeExtraEmail: `${contactUrl}/delete/contact/extra/email`,
    deleteContactTag: `${contactUrl}/delete-contact-tag`,
    getContactTagList: `${contactUrl}/get-contact-tag-list`,
    updateContactDetailsApi: `${contactUrl}/update-contact-details-v2`,
    getContactStages: `${contactUrl}/get-contact-stages`,
    changeDealStatus: `${contactUrl}/status-change-deals`,
    updateDeal: `${contactUrl}/update-contact-deal`,
    stageList: `${contactUrl}/stage-list`,
    deleteCollaborator: `${contactUrl}/delete-collaborators`,
    removeBulkContact: `${contactUrl}/bulk-contact-delete`,
    getActivityListContactDetails: `${contactUrl}/activity-list-contact-details`,
    formBuilderAWSPreSignedUrl: process.env.REACT_APP_FORM_BUILDER_S3_PRESIGNED,
    getContactSmartFormEntries: `${formApi17}/forms/entries`,
    changeContactSource: `${contactUrl}/contact-source-type/change/source`,
    userSourceList: `${contactUrl}/source-list-dropdown`,
    nylasAccessTokenConfig: `${profileUrl}/email-settings/nylas-access-token`,

    // add activity modal calender

    fetchUserConnectedCalendarList: `${rootUrl}/user-connected-calendar-list`,
    getUserInfo: `${contactUrl}/get-user-data`,
    conversations: `${inboxUrl}/contact-conversation-v2`,
    unsubscribeBulkEmail: `${bulkEmailBackendApi}/unsubscribe/campaign`,
    connectGoogleProcessToken: `${bulkEmailBackendApi}/campaigns/google/process-token`,
    updateNumberDncStatus: `${tenantBackendV1}/contacts/dnc/update-number-dnc-status`,
    sendMessage: `${inboxUrl}/make-conversation`,
    addNote: `${inboxUrl}/add-note`,
    getAllTeamUsers: `${contactUrl}/get-all-team-users`,
    emails: `${inboxUrl}/user-email-list`,
    uploadEmailFile: `${contactUrl}/upload-email-file`,
    removeFile: `${contactUrl}/file/remove`,
    getContactStagesProduct: `${contactUrl}/get-contact-stages-products`,
    getUserProductList: `${dealModuleUrl}/user-products/list`,
    contactRelationshipApi: `${tenantBackendV1}/contact-relationships`,
    insuranceCompanies: `${tenantBackendV1}/insurance-company/selected-company`,
    userPolicies: `${dealModuleUrl}/user-policies/list`,
    userContactStageProducts: `${dealModuleUrl}/user-contact-stage-products/policy`,
    updateSuccessDealPolicy: `${dealModuleUrl}/user-contact-stage-products/update-success-policy-deal`,
    callEnvironment: `${tenantBackendV1}/environment/call-environment`,
    lostDealFormData: `${tenantBackendV1}/deal/lost-deal-recycle/form-data`,
    stagesByPipeline: `${dealModuleUrl}/select-all-stage-by-pipeline`,
    lostDealRecycle: `${tenantBackendV1}/deal/lost-deal-recycle`,
    activeProductList: `${dealModuleUrl}/user-products/active-list`,
    checkUnsubscried: `${contactUrl}/check/contact/unsubscribe`,
    markStepComplete: `${insuranceOnboardUrl}/mark-step-complete`,
    searchVirtualNumberList: `${tenantBackendV1}/twilio/get-virtual-numbers`,
    buyVirtualNumberTwilio: `${tenantBackendV1}/twilio/buy-virtual-number`,
    getVirtualNumberListV2: `${tenantBackendV1}/virtual-number/get-user-virtual-number`,

}

export default Api;