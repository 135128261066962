import React, {useEffect, useRef, useState} from "react";
import {CallIcon} from "../../GlobalAddDealModalV3/components/Icons";
import {IconButton, styled} from "@material-ui/core";
import Utils from "../../addContact/Utils";
import GlobalDialer from "../../user/GlobalDialer/GlobalDialer";
import GlobalModal from "../../GlobalModal/Modal/GlobalModal";
import ContactDetailCallOutcome from "../../contactDetails";
import {storeMultipleReducers} from "../../../actions/globalDialerAction";
import {connect} from "react-redux";
import { HEADER_COLORS } from "./constants";

const HeaderGlobalDialerInsuranceWrapper = styled("div")(({ theme }) => ({
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    boxShadow: "0 0 17px 1px #bbb",
    right: "-135px",
    overflow: "visible",
    position: "absolute",
    top: "60px",
    width: "320px",
    zIndex: "999",

    "&:before": {
        background: "url(/static/media/arrow.20587642.svg) no-repeat 50% / 34px 24px",
        backgroundSize: "cover",
        content: "''",
        display: "block",
        height: "23px",
        position: "absolute",
        right: "44%",
        top: "-12px",
        width: "34px",
        zIndex: "-1"
    },

    "& .dial-input-wrapper svg": {
        background: "#d3d3d3",
        borderRadius: "50%",
        height: "18px",
        width: "18px",
        position: "absolute",
        top: "25px",
        right: "16px"
    },

    "& .dial-search-wrapper svg": {
        position: "absolute",
        left: "20px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: "3"
    },

    "& .list-all-contact h5": {
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: ".01em",
        columns: "var(--dark-blue)"
    },

    "& .contact-info ul": {
        margin: "0"
    }
}));

const HeaderGlobalDialerInsurance = (props) => {
    const [updateNumber, setUpdateNumber] = useState({ update: false, dialerContactNumber:null });
    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const [dialOccurrence, setDialOccurrence] = useState(0);
    const [openContactDetailModal, setOpenContactDetailModal] = useState(false);
    const [connectedCallerInfo, setConnectedCallerInfo] = useState(null);
    const buttonRef = useRef(null);
    const popoverRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)
            && popoverRef.current && !popoverRef.current.contains(event.target)) {
            setOpenSearchMenu(false);
        }
    };

    useEffect(() => {
        if(!openSearchMenu){
            setUpdateNumber((prev)=> { return { update: !(prev.update),dialerContactNumber:null }});
        }
    }, [openSearchMenu])

    window.initiateGlobalDialer = (contact = null, contactListInfo = null) => {
        if(contact != null){
            if(contact.dialToNumber) {
                props.storeMultipleReducers({ stage : "dial",dialNumber : contact.number })
                setDialOccurrence(occur => occur + 1);
                localStorage.setItem("isDialFromMap",true);
            }
            else {
                if(contactListInfo){
                    props.storeMultipleReducers({ listCurrentIndex : contactListInfo.currentIndex ?? 0, stage: "contact",
                        recentContacts: (contactListInfo.currentIndex) ?  contactListInfo.contactList ?? [contact] :  [contact]
                    })
                }
                setUpdateNumber({stage: "contact", update: !updateNumber.update,dialerContactNumber:contact.number, contactInfo: contact, contactListInfo: contactListInfo});
            }
        }else {
            setUpdateNumber({update: false,dialerContactNumber:null, contactInfo: null,contactListInfo: contactListInfo})
        }
        setTimeout(()=>{
            setOpenSearchMenu(true);
        },1000);
    }

    const closeDialog = () => {
        localStorage.setItem("isDialFromMap", "false");
        setOpenSearchMenu(false);
        setDialOccurrence(0);
    };

    const handleOpenContactDetailModal = ()=> {
        setOpenSearchMenu(false);
        setTimeout(()=>{
            setOpenContactDetailModal(true);
        },500);
    };

    const onCloseCallOutcomeModal=()=>{
        setOpenContactDetailModal(false);
        if(props.goToNextInList){
            let currentIndex = props.listCurrentIndex+1;
            if(props.recentContacts[currentIndex]){
                do{
                    if(props.recentContacts[currentIndex].number && props.recentContacts[currentIndex].number !== ""){
                        let contact = props.recentContacts[currentIndex];
                        props.storeMultipleReducers({ goToNextInList: false, listCurrentIndex : currentIndex });
                        setUpdateNumber({ update: !updateNumber.update,dialerContactNumber:contact.number, contactInfo: contact,
                            contactListInfo: { from: "contactListPage", contactList: props.recentContacts,
                                totalContacts: props.recentContacts.length ,currentIndex : currentIndex }});

                        currentIndex = -1
                    }else{
                        currentIndex++;
                    }
                }while(props.recentContacts[currentIndex] && currentIndex !== -1)
            }
        }
    };

    return(
        <div style={{ position: "relative" }}>
            <IconButton buttonRef={buttonRef} className="actionButton" onClick={()=>{ setOpenSearchMenu((prevState)=>(!prevState)); }}>
                <CallIcon color={HEADER_COLORS.CALL_ICON}/>{/*Call icon color Blue*/}
            </IconButton>
            <HeaderGlobalDialerInsuranceWrapper ref={popoverRef} style={{ visibility: openSearchMenu ? "visible" : "hidden" }}>
                {Utils.getAccountData("userId") !== 446 ?
                    <GlobalDialer
                        updateNumber={updateNumber}
                        openDialog={() => { setOpenSearchMenu(true); }}
                        closeDialog={() => closeDialog()}
                        setConnectedCalledInfo={(info) => setConnectedCallerInfo(info)}
                        OpenContactDetailModal={handleOpenContactDetailModal}
                        dialOccurrence={dialOccurrence}
                    /> :
                    <GlobalDialer
                        updateNumber={updateNumber}
                        openDialog={() => { setOpenSearchMenu(true); }}
                        closeDialog={() => closeDialog()}
                        setConnectedCalledInfo={(info) => setConnectedCallerInfo(info)}
                        OpenContactDetailModal={handleOpenContactDetailModal}
                        dialOccurrence={dialOccurrence}
                    />
                }
            </HeaderGlobalDialerInsuranceWrapper>

            {
                openContactDetailModal &&
                <GlobalModal
                    open={openContactDetailModal}
                    title="Contact Details"
                    onClose={() => onCloseCallOutcomeModal()}
                    buttonText={"Add Deal"}
                    hideFooter={true}
                    extra_props_active={true}
                >
                    <ContactDetailCallOutcome closeModal={() => { setOpenContactDetailModal(false)}}/>
                </GlobalModal>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        listCurrentIndex: state.globalDialerReducer.listCurrentIndex,
        goToNextInList: state.globalDialerReducer.goToNextInList,
        recentContacts: state.globalDialerReducer.recentContacts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderGlobalDialerInsurance);