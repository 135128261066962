import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Editor } from "@tinymce/tinymce-react";
import { Chip, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './video_email_modal.css';
import './video_modal_wrapper.css';
import { getContactDetailsFromDeal, getUserEmailList, sendVideoEmail, saveTemplate } from "./redux/videoEmailApi";
import VideoEmail from "./VideoEmail";
import { connect } from "react-redux";
import GlobalModal from "../../GlobalModal/Modal/GlobalModal";
import ScheduleSetting from "./ScheduleSetting";
import moment from "moment";
import CoreConstants from "../Video/CoreConstants";
import { updateDay, updateHour, updateLaterHour, updateLaterMin, updateMin, updateTabValue, updateTime } from "../../../actions/SendModulesAction";
import { Save } from "@material-ui/icons";
import EmailIcon from '@material-ui/icons/Email';
import { testEmailSend } from "../../../api/sendModulesApi";
import SimpleReactValidator from "simple-react-validator";
import ApiList from "../Email/assets/ApiList";
import GlobalCustomFieldGrouped from "../../GlobalCustomFieldGrouped/Index";
import { fetchFormList } from "../../../api/globalApi";
import BasicInput from '../../custom/BasicInput'
import BasicSelect from "../../custom/BasicSelect";
import SendEmailHelper from "../Email/assets/Helper";
import ToMail from "./ToMail";
import EmailSendSkeleton from "../Email/pages/EmailSendSkeleton";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  BasicInputStyle: {
    width: '500px !important'
  }, BasicSelectStyle: {
    width: '100% !important',
  },
  toBasicSelect: {
    width: '100% !important',
  },
  BasicInputStyleSub: {
    width: '100% ',
  },
  deleteChip: {
    height: '22px',
    backgroundColor: '#3c7ef3',
    color: '#fff',
    '& .MuiChip-deleteIcon': {
      WebkitTapHighlightColor: 'transparent',
      color: 'hsla(0, 0%, 100%, 0.7)',
      fontSize: '16px',
      cursor: 'pointer',
      margin: '0 4px 0 -4px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#777',
      cursor: 'pointer',
      lineHeight: 'unset',
      maxWidth: '120px',
      width: '100%',
    }
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const VideoEmailModal = props => {
  const editorRef = useRef(null);
  const classes = useStyles();
  const textInput = useRef(null);
  const bottomRef = useRef(null)
  const [bottomScroll, setBottomScroll] = useState(false);

  const [, forceUpdate] = useState()
  const validator = useRef(new SimpleReactValidator({
    autoForceUpdate: { forceUpdate: () => forceUpdate(1) },
    className: 'text-danger'
  }));
  const testEmailRef = useRef(null)
  const [isSendTestEmail, setIsSendTestEmail] = useState(false);

  const [type, setType] = useState(1);
  const [fromEmails, setFromEmails] = useState([]);
  const [selectedFromEmail, setSelectedFromEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailVideoId, setEmailVideoId] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [bcc, setBCC] = useState([]);
  const [displayBCC, setDisplayBCC] = useState(false);

  const templateTitle = useRef(null);
  const templateDescription = useRef(null);
  const folderId = useRef(null);

  const [sending, setSending] = useState(false);

  // for campaign video update
  const [updateSetting, setUpdateSetting] = useState(false)
  const [editableCampaignSetting, setEditableCampaignSetting] = useState(null)
  const [editableCampaignSettingId, setEditableCampaignSettingId] = useState(null)

  //for update quick reply
  const [currentQuickReply, setCurrentQuickReply] = useState(props.currentQuickReply ?? null);

  const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;
  const [relatedContactIds, setRelatedContactIds] = useState([]);
  const [toEmails, setToEmails] = useState([]);
  const [selectedToMail, setSelectedToMail] = useState('');
  const [cc, setCC] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (props.from !== 'campaign' && props.from !== 'quick_reply') {
      if (props.selectedContact.email === '' || props.selectedContact.email == null) {
        props.closeVideoEmailSendModal();
        if (window.showNotification !== undefined) {
          window.showNotification("ERROR", 'Contact has no email to send video email');
        }
      }
      getContactDetails();
    } else {
      setLoading(false)
    }

    if (props.userInfo != null) {
      let content = '<br />' + (props.userInfo.show_appointment_url === 1 ? (props.userInfo.appointment_url ? props.userInfo.appointment_url : '') : '');
      content += props.userInfo.show_signature === 1 ? (props.userInfo.signature ? props.userInfo.signature : '') : '';

      setEmailBody(content);
    }

  }, []);

  useEffect(() => {
    if (props.currentCampaignSetting != null) {
      setUpdateSetting(true)
      setEditableCampaignSetting(props.currentCampaignSetting)
      setEditableCampaignSettingId(props.currentCampaignSetting.id)
      setSubject(props.currentCampaignSetting.subject)
      setEmailBody(props.currentCampaignSetting.message)
      setEmailVideoId(props.currentCampaignSetting.email_video_id)
      // setVideoUrl(props.currentCampaignSetting?.campaign_email_video?.gif_link)
      setVideoUrl(props.currentCampaignSetting?.campaign_email_video?.video_link)

      let selectedTab = 0;
      let mint = 0;
      let hour = 0;
      let time = '12:00';
      let laterHour = '';
      let laterMin = '';
      if (props.currentCampaignSetting.type > 1) {
        if (props.currentCampaignSetting.type === 2 && props.currentCampaignSetting.day === 1) {
          selectedTab = 1;
          let timeData = props.currentCampaignSetting.hour.split(":");
          hour = parseInt(timeData[0]);
          mint = parseInt(timeData[1]);
        } else {
          selectedTab = props.currentCampaignSetting.type;
          let timeData = props.currentCampaignSetting.hour.split(":");
          laterHour = timeData[0];
          laterMin = timeData[1];
        }
        time = props.currentCampaignSetting.hour;
      }
      else {
        if (props.currentCampaignSetting.type === 1) {
          selectedTab = 0;
        } else {
          selectedTab = 1;
          let timeData = props.currentCampaignSetting.hour.split(":");
          hour = parseInt(timeData[0]);
          mint = parseInt(timeData[1]);
        }
      }
      props.updateTabValue(selectedTab)
      props.updateHour(hour)
      props.updateMin(mint)
      props.updateTime(time)
      props.updateDay(props.currentCampaignSetting.day);
      props.updateLaterHour(laterHour);
      props.updateLaterMin(laterMin);
    }
    else {
      props.updateTabValue(0)
    }
  }, [updateSetting])

  useEffect(() => {
    if (props.currentQuickReply) {
      setSubject(props.currentQuickReply.subject)
      setEmailBody(props.currentQuickReply.message)
      setEmailVideoId(props.currentQuickReply.campaign_video_file_for_email_id)
    }
  }, [props.currentQuickReply])

  const getContactDetails = () => {
    setLoading(true);
    getContactDetailsFromDeal({ id: props.id }).then(res => {
      let response = res.data;
      let toEmailsData = []
      if (!SendEmailHelper.isEmpty(response.people.email)) {
        toEmailsData = [{
          value: response.people.email,
          label: (response.people.email + " (Primary Email)")
        }]
      }

      let selectedEmail = [...toEmailsData];
      setToEmails(selectedEmail);
      setSelectedToMail(selectedEmail[0].value);
      let user = response.loggedOnUser;
      if (user !== undefined && user !== null) {
        if (user.agency.email_provider !== 0 && user.email_provider == 1) {
          try {
            let email = JSON.parse(user.nylas_email_provider_info).email;
            setFromEmails([{
              value: email,
              label: email
            }]);
            setSelectedFromEmail(email);
          } catch (error) {
            setFromEmails([])
          }
        } else {
          loadEmail();
        }
        let content = '<br />' + (user.show_appointment_url == 1 ? (user.appointment_url ? user.appointment_url : '') : '');
        content += user.show_signature == 1 ? (user.signature ? user.signature : '') : '';
        setEmailBody(content);
      }
    }).catch(error => {
      loadEmail();
    })
      .finally(() => {
        setLoading(false);
      });
  }

  const loadEmail = () => {
    getUserEmailList({})
      .then(response => {
        let array = [];

        for (let email in response.data.data) {
          array.push({
            value: email,
            label: email,
          })
        }

        if (typeof array[0] !== 'undefined') {
          setSelectedFromEmail(array[0].value);
        }

        setFromEmails(array)
      });
  }

  const handleSubjectPersonalize = (event) => {
    let start = textInput.current.selectionStart;
    let end = textInput.current.selectionEnd;
    let text = textInput.current.value;
    let before = text.substring(0, start);
    let after = text.substring(end, text.length);
    let newText = (before + event + after);

    setSubject(newText);

    setTimeout(() => {
      textInput.current.selectionStart = textInput.current.selectionEnd = start + event.length;
      textInput.current.focus();
    }, 500);
  }

  const handleBodyPersonalize = (event) => {
    editorRef.current.insertContent(event);
    setTimeout(() => {
      editorRef.current.focus();
    }, 500);
  }

  const saveAsTemplate = sendData => {
    saveTemplate(sendData)
  }

  const videoEmailSend = event => {
    event.preventDefault();

    let sendData = {
      subject: subject,
      message: emailBody,
      message_type: CoreConstants.MESSAGE_TYPE.VIDEO_EMAIL,
      email: selectedFromEmail,
      willSaveTemplate: props.willSaveAsTemplate,
      schedule_type: 1,
      time: null,
      type: type,
      date: null,
      origin: 3,
      email_video_id: emailVideoId,
      cc: cc[0] ? cc.join(', ') : '',
    };

    if (props.relationSuperContactId) {
      sendData.relation_super_contact_id = props.relationSuperContactId;
    }

    if (relatedContactIds && relatedContactIds[0]) {
      sendData.related_contact_ids = relatedContactIds;
    }

    // check validation
    if (subject == '') {
      if (window.showNotification !== undefined) {
        window.showNotification("ERROR", "Can not send email without subject");
      }
      document.getElementById("email-setting-subject-input-field").focus();
      return false
    }
    // else if(emailBody == ''){
    //     if(window.showNotification !== undefined){
    //         window.showNotification("ERROR", "Can not send email without mail body text");
    //     }
    //     return false
    // } 
    else if (emailVideoId == null) {
      if (window.showNotification !== undefined) {
        window.showNotification("ERROR", "No video selected. If you recorded the video you need to click the check button, stating that you are satisfied with the video");
      }
      return false
    } else if ((props.willSaveAsTemplate || props.from === 'quick_reply') && templateTitle.current.value == '') {
      if (window.showNotification !== undefined) {
        window.showNotification("ERROR", "Please add title to save as template");
      }
      return false
    }

    if (props.willSaveAsTemplate) {
      sendData.template_title = templateTitle.current.value;
      sendData.template_description = templateDescription.current.value;
      sendData.folder_id = folderId.current.value;

      let templateData = {
        content: CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL,
        title: templateTitle.current.value,
        videoId: emailVideoId,
        email_subject: subject,
        email_body: emailBody,
      }

      saveTemplate(templateData).then(response => {
        // console.log(response);
      });
    }

    if (props.from === 'campaign') {
      let type;
      switch (props.tabValue) {
        case 1:
        case 2:
          type = CoreConstants.SENDING_TYPE.SCHEDULE;
          break;
        case 0:
          type = CoreConstants.SENDING_TYPE.IMMEDIATE;
          break;
        default:
          type = props.tabValue;
          break;
      }

      if (props.currentCampaignSetting) {
        sendData.id = props.currentCampaignSetting.id;
      }
      sendData.campaign_id = props.campaignId;
      sendData.nScheduleHours = props.hour;
      sendData.nScheduleMinutes = props.min;
      sendData.nScheduleDays = props.day;
      sendData.followupScheduleTime = props.time;
      sendData.type = type;
      sendData.day_type = (props.tabValue != 0 ? props.tabValue : 1);
      sendData.date = moment(props.startDate).format('YYYY-MM-DD');
      sendData.greeting_option = props.greetingOption;

      props.responseCallback(sendData);
    } else if (props.from === 'quick_reply') {
      if (currentQuickReply !== null) {
        sendData.id = currentQuickReply.id;
        sendData.content = currentQuickReply.type;
      } else {
        sendData.content = CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL;
      }
      sendData.title = templateTitle.current.value;


      props.responseCallback(sendData);
    } else {
      sendData.contact_id = props.selectedContact.id;

      if (!sending) {
        setSending(true);
        setLoading(true);

        sendVideoEmail(sendData)
          .then(response => {
            setSending(false);
            if (response.data.status === 'success') {
              window.showNotification('success', response.data.html);
              props.closeVideoEmailSendModal();
              if (props.responseCallback) {
                props.responseCallback(response.data);
              }
            } else {
              if (/forbidden|grant/i.test(response.data.html)) {
                window.showNotification('ERROR', "Please reconnect your email. <a href='/user/profile/email-settings'>Reconnect</a>");
              } else {
                window.showNotification('ERROR', response.data.html);
              }
            }

          })
          .finally(() => {
            setLoading(false);
          });
      }
    }

  }

  const useMessageTemplate = data => {
    setSubject(data.subject);
    setEmailBody(data.message);
    setEmailVideoId(data.campaign_video_file_for_email_id);
  }

  const renderFromEmails = () => {
    let fromEmailOptions = [];

    fromEmails.map((data, index) => {
      fromEmailOptions.push(
        <MenuItem className="dropdownhelper-menuitem-class" value={data.value}>{data.label}</MenuItem>
      );
    });

    return fromEmailOptions;
  }

  const scrollToBottom = () => {
    if (!bottomScroll) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" })
      setBottomScroll(true)
    }
  }

  const getSendButtonText = () => {
    let text = '';

    switch (props.from) {
      case 'campaign':
        text = sending ? "Saving ..." : "Save";
        break;
      case 'inbox':
      case 'contact':
        text = sending ? "Sending ..." : "Send Email";
        break;
      case 'quick_reply':
        text = sending ? "Saving ..." : "Save Template";
        break;
      default:
        text = sending ? "Sending ..." : "Send Email";
        break;
    }

    return text;

  }

  const getButtonIcon = () => {
    let icon = '';

    switch (props.from) {
      case 'campaign':
      case 'quick_reply':
        icon = <Save />;
        break;
      case 'inbox':
      case 'contact':
        icon = <EmailIcon />;
        break;
      default:
        icon = <EmailIcon />;
        break;
    }

    return icon;

  }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const sendTestEmail = (e) => {
    e.preventDefault();
    let test_email = testEmailRef.current.value;

    if (test_email == "" && !validateEmail(test_email)) {
      window.showNotification('ERROR', "Please insert a valid email");
      return;
    }

    if (!validator.current.allValid()) {
      window.showNotification('ERROR', "Valid email subject and message are not found");
      return;
    }

    if (!isSendTestEmail) {
      setIsSendTestEmail(true);
      testEmailSend({
        email: test_email,
        subject: subject,
        message: emailBody,
        email_video_id: emailVideoId,
        campaign_id: props.campaignId,
      }).then(response => {
        setIsSendTestEmail(false);
        if (response.data.status) {
          window.showNotification('SUCCESS', response.data.message);
        } else {
          if (/forbidden|grant/i.test(response.data.message)) {
            window.showNotification('ERROR', "Please reconnect your email. <a href='/user/profile/email-settings'>Reconnect</a>");
          } else {
            window.showNotification('ERROR', response.data.message);
          }
        }
      }).catch(error => {
        window.showNotification('ERROR', 'Something wrong! Try again');
        setIsSendTestEmail(false);
      })
    }
  }

  const apiKeys = () => {
    return "f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x";
  }


  return (
    <GlobalModal
      className={
        "video_library_modal_wrapper videoEmailModalWrapperV3Delete full-height-video-mail-modal video__mail__modal__top__wrapper global-large-modal"
      }
      open={props.openVideoEmailModal}
      onClose={() => props.closeVideoEmailSendModal()}
      title={
        props.from !== "quick_reply" ? " New Video Email" : "Save as Quick Reply"
      }
      img={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/video_email_icon.png`}
      buttonText={getSendButtonText()}
      buttonIcon={getButtonIcon()}
      handleSubmit={videoEmailSend}
      extra_props_active={true}
    >
      {loading ? <EmailSendSkeleton /> :
        (<>

          {props.from !== "campaign" && props.from !== "quick_reply" && (
            <React.Fragment>
              <ToMail
                toEmails={toEmails}
                selectedToMail={selectedToMail}
                setSelectedToMail={setSelectedToMail}
                relationSuperContactId={props.relationSuperContactId}
                id={props.id}
                cc={cc}
                setCC={setCC}
              />
              <div style={{ display: 'flex', alignItems: "center", height: '50px' }}>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#777',
                    cursor: 'pointer',
                    lineHeight: 'unset',
                    maxWidth: '120px',
                    width: '100%'
                  }}
                >
                  <h5 className="video__email__left__label">From:</h5>
                </div>
                <div style={{ display: 'flex', alignItems: "center", width: '100%' }}>
                  <div className={classes.BasicSelectStyle}>
                    <BasicSelect
                      name="fromEmail"
                      options={fromEmails}
                      defaultText=""
                      value={selectedFromEmail}
                      onChange={(e) => setSelectedFromEmail(e.target.value)}
                      mapping={{
                        label: "label",
                        value: "value",
                      }}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {props.from === "quick_reply" && (
            <div className="row mb-2">
              <div className="col s2">
                <h5 className="video__email__left__label">Title:</h5>
              </div>
              <div className="align-items-center col d-flex s10">
                <div className="video__select__input__wr">
                  <div className="video__select__input__wr__left">
                    <input
                      className="video__select__input___left"
                      defaultValue={
                        props.currentQuickReply
                          ? props.currentQuickReply.title
                          : ""
                      }
                      type="text"
                      placeholder="Enter the title"
                      ref={templateTitle}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div style={{ display: 'flex', alignItems: "center", height: '50px' }}>
            <div style={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#777',
              cursor: 'pointer',
              lineHeight: 'unset',
              maxWidth: '120px',
              width: '100%'
            }}>
              <h5 className="video__email__left__label">Subject:</h5>
            </div>
            <div className="align-items-center col d-flex s10" style={{ width: '100%' }}>
              <div className="video__select__input__wr" style={{ maxWidth: 'unset !important', border: 'unset !important', gap: '12px' }}>
                <div className={classes.BasicInputStyleSub}>
                  <BasicInput
                    type="text"
                    placeholder="Enter your subject"
                    id="email-setting-subject-input-field"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    inputRef={textInput}
                    fullWidth
                  />
                </div>
                <div className="video__select__input__wr__right personiliz__input__alt">
                  <GlobalCustomFieldGrouped
                    className="video_mail_module__global_custom__field"
                    labelText="Personalized"
                    handleSelect={handleSubjectPersonalize}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="modal__scroll v3_modal__scroll">
            <VideoEmail
              from={props.from}
              emailVideoId={emailVideoId}
              setEmailVideoId={setEmailVideoId}
              useMessageTemplate={useMessageTemplate}
              templateTitle={templateTitle}
              templateDescription={templateDescription}
              folderId={folderId}
              videoUrl={videoUrl}
            />

            {/*Massaging Editor*/}
            <div className="tiny-mce mt-3">
              <div className="mb-0 row">
                <div className="col s12">
                  <Editor
                    apiKey={apiKeys()}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={emailBody}
                    cloudChannel="5-dev"
                    textareaName="email_reply"
                    init={{
                      height: "50vh",
                      browser_spellcheck: true,
                      menubar: "file edit view insert format tools table",
                      plugins: [
                        "advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak",
                        "searchreplace visualblocks visualchars code nonbreaking",
                        "insertdatetime table contextmenu code help wordcount template",
                      ],
                      convert_urls: false,
                      toolbar:
                        "insertfile undo redo | styleselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | link image | \
                                        bullist numlist outdent indent | removeformat | print preview fullpage | help",
                      //bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                      imagetools_toolbar:
                        "rotateleft rotateright | flipv fliph | editimage imageoptions",
                      imagetools_proxy: emailUploadFileApi,
                      images_upload_handler: ApiList.image_upload_handler,
                      automatic_uploads: true,
                      file_picker_types: "file image media",
                      placeholder: "Compose your mail message",
                      target_list: [
                        { title: "Same page", value: "_self" },
                        { title: "New page", value: "_blank" },
                      ],
                      link_list: function (success) {
                        let view = [];
                        fetchFormList().then((res) => {
                          if (res.data.status != "undefined") {
                            view = res.data.html;
                            success(view);
                          }
                        });
                      },
                      forced_root_block: false,
                    }}
                    onEditorChange={setEmailBody}
                  />
                  {/*<textarea id="basic-example" className="materialize-textarea" placeholder="Type a message"  onChange={(event) => setConversationText(event.target.value)} ref={textInput} value={conversationText}/>*/}
                </div>
              </div>
              {validator.current.message("subject", emailBody, "required|string")}
            </div>
            {/*Massage Editor Bottom Button*/}
            <div className="send-button-area v3_video_email_send_button">
              <div className="mb-0 row">
                <div className="col s6">
                  <div className="mb-0 d-flex align-items-center">
                    <div className="input-field  personiliz__extra__alt">
                      <GlobalCustomFieldGrouped
                        className="video_mail_module__global_body__custom__field"
                        labelText="Personalized"
                        handleSelect={handleBodyPersonalize}
                      />
                    </div>
                  </div>
                </div>
                <div className="col s6 d-none">
                  <div className="pr-0 send-message-btn right p-relative">
                    <div className="file-field input-field d-flex">
                      <div className="file-path-wrapper d-flex align-items-center">
                        <span>
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.5 0C5.6075 0 0 5.60758 0 12.5001C0 19.3926 5.6075 25 12.5 25C19.3925 25 25 19.3926 25 12.5001C25 5.60758 19.3925 0 12.5 0ZM12.5 22.7273C6.86061 22.7273 2.27273 18.1394 2.27273 12.5001C2.27273 6.86076 6.86061 2.27273 12.5 2.27273C18.1394 2.27273 22.7273 6.86076 22.7273 12.5001C22.7273 18.1394 18.1393 22.7273 12.5 22.7273Z"
                              fill="#FF6B6B"
                            />
                            <path
                              d="M12.4992 5.30273C11.6639 5.30273 10.9844 5.98273 10.9844 6.81857C10.9844 7.65364 11.6639 8.33304 12.4992 8.33304C13.3345 8.33304 14.0141 7.65364 14.0141 6.81857C14.0141 5.98273 13.3345 5.30273 12.4992 5.30273Z"
                              fill="#FF6B6B"
                            />
                            <path
                              d="M12.4996 10.6045C11.8721 10.6045 11.3633 11.1133 11.3633 11.7409V18.559C11.3633 19.1866 11.8721 19.6954 12.4996 19.6954C13.1272 19.6954 13.636 19.1866 13.636 18.559V11.7409C13.636 11.1133 13.1272 10.6045 12.4996 10.6045Z"
                              fill="#FF6B6B"
                            />
                          </svg>
                        </span>
                        <input
                          className="file-path validate"
                          type="text"
                          placeholder=" Max size 15MB, Max 5 files."
                        />
                      </div>
                      <div className="upload_file_button d-flex">
                        <span className="upload_file_button_icon">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.288 5.10351L6.16506 12.1474C4.98482 13.3147 3.07111 13.3147 1.89087 12.1474C0.710641 10.9802 0.710641 9.08801 1.89087 7.92077L8.30191 1.58131C9.0889 0.803315 10.3642 0.803315 11.1512 1.58131C11.9382 2.35929 11.9382 3.6211 11.1512 4.39908L4.74016 10.7386C4.34692 11.1278 3.70901 11.1278 3.31527 10.7386C2.92202 10.3498 2.92202 9.71894 3.31527 9.32967L9.01386 3.69463L8.3014 2.9902L2.60333 8.62572C1.81634 9.40372 1.81634 10.6655 2.60333 11.4435C3.39032 12.2215 4.66563 12.2215 5.45262 11.4435L11.8637 5.10401C13.0439 3.93679 13.0439 2.04461 11.8637 0.877366C10.6834 -0.289851 8.76973 -0.289851 7.58949 0.877366L0.822208 7.56904L0.846732 7.59355C-0.382548 9.15652 -0.274481 11.416 1.17844 12.8523C2.63136 14.2887 4.91528 14.3963 6.49626 13.18L6.52078 13.2046L14 5.80844L13.288 5.10351Z"
                              fill="#3C7EF3"
                            />
                          </svg>
                        </span>
                        <span>Attach File</span>
                        <input type="file" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.from === "campaign" && (
              //Time schedule
              <ScheduleSetting
                showAllTimeSetting={props.showAllTimeSettings}
                tabChangeCallback={scrollToBottom}
              />
            )}
            <div ref={bottomRef} />

            {props.from === "campaign" && (
              <div className="send_test_wrapper mt-5">
                <div className="send_test">
                  <label className="mb-2">Send Test Email</label>
                  <div className="inputs">
                    <input
                      type="email"
                      className="form-control"
                      ref={testEmailRef}
                      placeholder="Your email here..."
                    />
                    <a
                      href="#"
                      className="btn accent--bg--text--color"
                      onClick={(e) => sendTestEmail(e)}
                    >
                      {isSendTestEmail ? "Sending ..." : "Send Test Email"}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>)}
    </GlobalModal>
  );
}

const mapStateToProps = state => {
  return {
    willSaveAsTemplate: state.videoEmailReducer.willSaveAsTemplate,

    tabValue: state.videoEmailReducer.tabValue,
    hour: state.videoEmailReducer.hour,
    min: state.videoEmailReducer.min,
    day: state.videoEmailReducer.day,
    recurringDay: state.videoEmailReducer.recurringDay,
    laterHour: state.videoEmailReducer.laterHour,
    laterMin: state.videoEmailReducer.laterMin,
    time: state.videoEmailReducer.time,
    startDate: state.videoEmailReducer.startDate,
    arrSlotTimeHour1: state.videoEmailReducer.arrSlotTimeHour1,
    arrSlotTimeMin1: state.videoEmailReducer.arrSlotTimeMin1,
    greetingOption: state.videoEmailReducer.greetingOption,
    greetingsOptionTimeMin1: state.videoEmailReducer.greetingsOptionTimeMin1,
    greetingsOptionTimeHour1: state.videoEmailReducer.greetingsOptionTimeHour1,
    recurringTimeHour1: state.videoEmailReducer.recurringTimeHour1,
    recurringTimeMin1: state.videoEmailReducer.recurringTimeMin1,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTabValue: (params) => dispatch(updateTabValue(params)),
    updateHour: (params) => dispatch(updateHour(params)),
    updateMin: (params) => dispatch(updateMin(params)),
    updateDay: (params) => dispatch(updateDay(params)),
    updateTime: (params) => dispatch(updateTime(params)),
    updateLaterHour: (params) => dispatch(updateLaterHour(params)),
    updateLaterMin: (params) => dispatch(updateLaterMin(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoEmailModal);