import { useContext, useRef } from 'react';
import Personalized from '../components/personalized/Personalized';
import { updateAttachment, updateIsShowQuickReplyFlag, updateMessage, updateSaveAsFlag, updateSaveAsTitle } from '../reducers/Action';
import { EmailSendContext } from '../reducers/GlobalReduers';
import { Editor } from "@tinymce/tinymce-react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ApiList from '../assets/ApiList';
import Uploader from '../components/Uploader/Uploader';
import GlobalCustomFieldGrouped from "../../../GlobalCustomFieldGrouped/Index";
import { fetchFormList } from "../../../../api/globalApi";
import Utils from '../../../../helpers/Utils';
import BasicInput from '../../../custom/BasicInput';

const Message = () => {
    const { states, dispatch } = useContext(EmailSendContext)
    const messageInputRef = useRef(null)

    const handleMessagePersonalize = (value) => {
        messageInputRef.current.insertContent(value);
        // dispatch(updateMessage({
        //     message: (states.message + value),
        //     validMessage: false,
        // }))
        setTimeout(() => {
            messageInputRef.current.focus();
        }, 100);
    }

    const handleTemplateView = () => {
        dispatch(updateIsShowQuickReplyFlag(true))
    }

    const handleMessageChange = (value) => {
        dispatch(updateMessage({
            message: value,
            validMessage: false,
        }))
    }

    const handleSaveAs = () => {
        dispatch(updateSaveAsFlag(!states.saveAsTemplate))
    }

    const handleAttachment = (images) => {
        dispatch(updateAttachment(images))
    }

    const handleTitleChange = (event) => {
        dispatch(updateSaveAsTitle(event.target.value))
    }

    const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

    const apiKeys = () => {
        return "f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x";
    }
    return (
        <div className="g-email-send-message-content">
            <div className="g-message">
                <Editor
                    apiKey={apiKeys()}
                    onInit={(evt, editor) => messageInputRef.current = editor}
                    value={states.message}
                    textareaName='email_reply'
                    init={{
                        height: 300,
                        browser_spellcheck: true,
                        menu: {
                            favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                        },
                        menubar: 'favs file edit view insert format tools table help',
                        // menubar: 'favs file edit view format tools table help',
                        plugins: [
                            'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                            // 'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                            'searchreplace visualblocks visualchars code fullscreen nonbreaking',
                            // 'insertdatetime media table contextmenu paste code help wordcount template'
                            'insertdatetime table contextmenu code help wordcount template'
                        ],
                        convert_urls: false,
                        toolbar:
                            'insertfile undo redo | styleselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | link image | \
                            bullist numlist outdent indent | removeformat | print preview fullpage | help',
                        //bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                        imagetools_proxy: emailUploadFileApi,
                        images_upload_handler: ApiList.image_upload_handler,
                        automatic_uploads: true,
                        file_picker_types: 'file image media',
                        placeholder: "Compose your mail message",
                        target_list: [
                            { title: 'Same page', value: '_self' },
                            { title: 'New page', value: '_blank' },
                        ],
                        link_list: function (success) {
                            let view = [];
                            fetchFormList().then(res => {
                                if (res.data.status != 'undefined') {
                                    view = res.data.html;
                                    success(view);
                                }
                            });
                        },
                        forced_root_block: false
                    }}
                    onEditorChange={(value) => handleMessageChange(value)}
                    id="g-email-send-editor"
                />
                {(states.validMessage && states.message == '') &&
                    <span className="g-message-error">Email message is required!</span>
                }
            </div>
            <div className="g-save-as-content">
                <FormControl component="fieldset">
                    <FormControlLabel
                        control={<Checkbox checked={states.saveAsTemplate} onChange={handleSaveAs} name="save-as-template" />}
                        label="Save as quick reply"
                    />
                </FormControl>
                <div className="g-email-send-options">
                    <GlobalCustomFieldGrouped className={"send_module__quick__reply__global_custom__field"} labelText={"Personalized"} handleSelect={handleMessagePersonalize} />
                    <div className="g-template-button" onClick={handleTemplateView}>Use Quick Reply</div>
                    {/* <div className="g-template-button" onClick={handleTemplateView}>Attachment</div> */}
                </div>
            </div>
            {states.saveAsTemplate &&
                <div className="g-subject" style={{paddingBottom:"10px"}}>
                    {/* <input type="text"
                    className="-g-subject-input g__subject__input__alt" 
                    placeholder="Enter quick reply title" 
                    value={states.saveTemplateTitle} 
                    onChange={handleTitleChange} 
                    /> */}
                    <BasicInput
                        value={states.saveTemplateTitle} 
                        onChange={handleTitleChange} 
                        placeholder="Enter quick reply title" 
                        fullWidth
                    />
                </div>
            }
            <div className="g-attachment-content">
                <Uploader imagesList={(list) => handleAttachment(list)} updateComponent={states.attachmentFiles} />
            </div>
        </div>
    )
}
export default Message;