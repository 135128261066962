import React, { useEffect, useState } from "react";
import { List, styled } from "@material-ui/core";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchNotificationCount,
  incrementNotificationCount,
  setSiteNotificationLoaded,
} from "../../../actions/notificationAction";
import { getAgencyTwilioSurChargeApi, viewUserMapFeatureApi } from "../../../api/globalApi";
import Utils from "../../../helpers/Utils";
import useDelayCallback from "../../../hooks/useDelayCallback";
import { useIsMounted } from "../../../hooks/useIsMounted";
import CardMultiStepForm from "../../DirectCardSend/CardMultiStepForm";
import Helper from "../../DirectCardSend/assets/Helper";
import { storeInitialStates } from "../../DirectCardSend/redux/cardBroadcastAction";
import { SideNavLeftIcon } from "../../GlobalAddDealModalV3/components/Icons";
import SidebarCustomMenuInsurance from "./SidebarCustomMenuInsurance";
import SidebarItemInsurance from "./SidebarItemInsurance";
import SidebarPopoverMenuInsurance from "./SidebarPopoverMenuInsurance";
import { INSURANCE_SIDEBAR } from "./constants";
import { AGENCY_BUSINESS_TYPE } from "../../../helpers/CoreConstant";

const SidebarInsuranceWrapper = styled("aside")(
  ({ theme, isSidebarCollapsed }) => ({
    padding: isSidebarCollapsed ? "8px 1px 8px 8px" : "12px 1px 12px 12px",
    backgroundColor: "var(--sidebar-background)",
    "& .sidebarNav": {
      padding: "0px 2px 0px 0px",
      "& .MuiTypography-root": {
        fontSize: 14,
      },
      "& .sidebarItem .MuiTypography-root": {
        color: "var(--sidebar-text-color) !important",
      },
      "& .sidebarItem": {
        background: "transparent !important",
        "& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock":
        {
          color: "var(--sidebar-text-color) !important",
        },
      },
      "& .Mui-selected": {
        backgroundColor: "var(--sidebar-hover-color) !important",
        boxShadow: "0px 1px 3px 0px #1018281A",
        borderRadius: 12,

        "& .MuiTypography-root": {
          color: "var(--sidebar-text-color) !important",
          fontWeight: 600,
        },
      },
      "& .MuiListItem-button:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
      "& .MuiListItem-button": {
        borderRadius: 12,
      },
      "& .MuiListItem-button:hover": {
        backgroundColor: "var(--sidebar-hover-color) !important",
        borderRadius: 12,
      },
      "& .MuiListItem-gutters": {
        padding: isSidebarCollapsed ? "8px 10px" : "4px 6px",
      },
      "& .MuiListItemIcon-root": {
        minWidth: isSidebarCollapsed ? 35 : 30,
        justifyContent: isSidebarCollapsed ? "center" : "flex-start",
      },
      "& .MuiListItemText-root": {
        minWidth: 110,
        display: isSidebarCollapsed ? "none" : "block",
      },
      "& .MuiListItemSecondaryAction-root": {
        display: isSidebarCollapsed ? "none" : "block",
        right: 8,
      },

      "& .MuiListItem-root.Mui-focusVisible:not(:hover)": {
        backgroundColor: "transparent",
      },
    },

    "& .insuranceSidebarScrollbar": {
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
        backgroundColor: "#2c3e50",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "var(--purple)",
        borderRadius: "10px",
      },
    },

    "& .toggleButton": {
      position: "fixed",
      bottom: "17px",

      backgroundColor: "#3e50f7",
      width: "26px",
      height: "26px",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      cursor: "pointer",
    },
  })
);

const SidebarInsurance = ({ menuCollapsed, getUserSettingData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMount = useIsMounted();
  const { inboxCount, notificationCount, siteNotificationsLoaded } =
    useSelector((state) => state.notificationReducer);
  const [selectedSidebarItem, setSelectedSidebarItem] = useState("dashboard");
  const [directMailInfos, setDirectMailInfos] = useState({ open: false });
  const [showMapFeatureOption, setShowMapFeatureOption] = useState(false);
  const [menuItems, setMenuItems] = useState(INSURANCE_SIDEBAR());

  const FREE_MAP_ACCESS_AGENCIES = [1, 236];

  const isTemplateUser = Utils.getAccountData("isTemplateUser");
  const userOnboard = (Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE) ? Utils.getAccountData("userOnboard") : false;

  useEffect(() => {
    if (!isTemplateUser && !userOnboard) {
      if (!FREE_MAP_ACCESS_AGENCIES.includes(Utils.getAccountData("agencyId"))) {
        viewUserMapFeatureApi()
          .then((res) => {
            let response = res.data;

            if (
              response.status === "success" &&
              response.isUserMapFeatureView === true
            ) {
              setShowMapFeatureOption(true);
              sidebarItemVisibilityChange("map_finder", true);
            } else {
              setShowMapFeatureOption(false);
              sidebarItemVisibilityChange("map_finder", false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        setShowMapFeatureOption(true);
        sidebarItemVisibilityChange("map_finder", true);
      }
    }
  }, []);

  useEffect(() => {
    try {
      if (Utils.getAccountData("agencyTwilioSurCharge") !== undefined) {
        if (Utils.getAccountData("agencyTwilioSurCharge") > 0) {
          sidebarItemVisibilityChange("report.bill-report", true);
        }
      } else {
        getAgencyTwilioSurChargeApi().then((res) => {
          let response = res.data;
          if (response.status === "success") {
            if (response.data > 0) {
              sidebarItemVisibilityChange("report.bill-report", true);
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useDelayCallback(() => {
    if (isMount) {
      if (getUserSettingData) {
        getUserSettingData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSidebarItem]);

  const gotoUrl = (
    pathname,
    module,
    state = null,
    search = null,
    target = undefined
  ) => {
    if (pathname !== window.location.pathname) {
      if (target !== null && target !== undefined) {
        window.open(pathname, target);
        return;
      }

      setSelectedSidebarItem(module);

      let location = {
        pathname: pathname,
      };

      if (state) {
        location.state = state;
      }

      if (search) {
        location.search = search;
      }

      history.push(location);
    }
  };

  const addressCheck = (contactInfos) => {
    if (Helper.isEmpty(contactInfos.address)) {
      return false;
    }
    try {
      if (contactInfos.address.trim === "") {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  const setViewCardModal = (boolean) => {
    if (!boolean) {
      setDirectMailInfos({ open: false });
    }
  };

  const callBackGlobalDirectMailSend = (data) => {
    if (
      directMailInfos.directMailCallBack !== undefined &&
      directMailInfos.directMailCallBack != null
    ) {
      directMailInfos.directMailCallBack(data);
    }

    setDirectMailInfos({ open: false });
  };

  const sidebarItemVisibilityChange = (module, visibility) => {
    const [mainModule, subModule] = module.split(".");

    setMenuItems((prevMenuItems) =>
      prevMenuItems.map((item) => {
        if (item.module === mainModule) {
          if (item.sub_menus && subModule) {
            const updatedSubMenus = item.sub_menus.map((subMenu) =>
              subMenu.module === subModule
                ? { ...subMenu, visible: visibility }
                : subMenu
            );
            return { ...item, sub_menus: updatedSubMenus };
          } else {
            return { ...item, visible: visibility };
          }
        }
        return item;
      })
    );
  };

  window.setActiveSidebar = (module) => {
    setSelectedSidebarItem(module);
  };

  window.globalTimezoneConversionToDifferentTimezone = (
    inputTime,
    currentTimezone = "UTC",
    convertTimezone = "",
    format = "MM-DD-YYYY HH:mm:ss",
    returnCurrentDateWhenError = true
  ) => {
    try {
      if (convertTimezone === "") {
        convertTimezone = Utils.getAccountData("userTimezoneRaw");
      }
      if (format === "") {
        format = "MM-DD-YYYY HH:mm:ss";
      }
      const currentTimeInGivenTimezone = moment.tz(inputTime, currentTimezone);
      if (format === "from_now") {
        return currentTimeInGivenTimezone.clone().tz(convertTimezone).fromNow();
      }
      return currentTimeInGivenTimezone
        .clone()
        .tz(convertTimezone)
        .format(format);
    } catch (e) {
      if (!returnCurrentDateWhenError) {
        return "";
      }
      return moment(new Date()).format(format);
    }
  };

  window.getUserCurrentTimezone = () => {
    return Utils.getAccountData("userTimezoneRaw");
  };

  window.updateNotificationCount = (increment = false, live = false) => {
    if (!increment || inboxCount <= 10) {
      if (live) {
        dispatch(fetchNotificationCount({ needInboxCountLive: true }));
      } else {
        dispatch(fetchNotificationCount());
      }
    } else if (notificationCount <= 10) {
      dispatch(incrementNotificationCount());
    }

    if (siteNotificationsLoaded) {
      dispatch(setSiteNotificationLoaded(false));
    }
  };

  window.getUnreadMessageCount = () => {
    return inboxCount;
  };

  window.openGlobalDirectMailModal = (info) => {
    dispatch(storeInitialStates());
    let [
      contactDetails,
      messageType,
      directMailCallBack,
      directMailContactId,
      editableCampaign,
      directMailFrom,
      directMailCampaign,
    ] = [null, "", null, "", null, "", null];
    if (info.messageType) {
      if (info.contactDetails) {
        if (addressCheck(info.contactDetails)) {
          contactDetails = info.contactDetails;
          messageType = info.messageType;

          if (info.callBack !== undefined && info.callBack != null) {
            directMailCallBack = info.callBack;
          }

          if (info.contactId !== undefined && info.contactId != null) {
            directMailContactId = info.contactId;
          }

          if (
            info.editableCampaign !== undefined &&
            info.editableCampaign != null
          ) {
            editableCampaign = info.editableCampaign;
          }

          if (info.from !== undefined && info.from != null) {
            directMailFrom = info.from;
          }
        } else {
          window.showNotification("ERROR", "Contact has no address");
        }
      } else {
        messageType = info.messageType;

        if (info.callBack) {
          directMailCallBack = info.callBack;
        }

        if (info.contactId) {
          directMailContactId = info.contactId;
        }

        if (info.editableCampaign) {
          editableCampaign = info.editableCampaign;
        }

        if (info.from) {
          directMailFrom = info.from;
        }

        if (info.directMailCampaign) {
          directMailCampaign = info.directMailCampaign;
        }
      }

      setDirectMailInfos({
        open: true,
        contactDetails: contactDetails,
        messageType: messageType,
        directMailCallBack: directMailCallBack,
        directMailContactId: directMailContactId,
        editableCampaign: editableCampaign,
        directMailFrom: directMailFrom,
        directMailCampaign: directMailCampaign,
      });
    } else {
      window.showNotification("ERROR", " No directMail Type selected");
    }
  };

  window.getShowMapFeatureOption = () => {
    return showMapFeatureOption;
  };

  const sidebarToggle = (e) => {
    e.preventDefault();
    if (menuCollapsed) {
      window.setCollapsedMenu(false);
    } else {
      window.setCollapsedMenu(true);
    }
  };

  return (
    <SidebarInsuranceWrapper
      id="sidenav-left"
      className="awesome__scroll_bar bg_dark_blue main__sidebar box-shadow-none"
      isSidebarCollapsed={menuCollapsed}
    >
      <div
        className="insuranceSidebarScrollbar"
        style={{
          position: "relative",
          height: "calc(100vh - 150px)",
          overflowX: "hidden",
          padding: 0,
        }}
      >
        {directMailInfos.open && (
          <CardMultiStepForm
            onClose={() => {
              setViewCardModal(false);
            }}
            from={
              directMailInfos.directMailFrom !== ""
                ? directMailInfos.directMailFrom
                : "inbox"
            }
            callBack={callBackGlobalDirectMailSend}
            directMailInfos={directMailInfos}
          />
        )}

        <List component="div" className="sidebarNav">
          {menuItems.map((item, index) =>
            item.visible ? (
              item.sub_menus && !item.url ? (
                <SidebarPopoverMenuInsurance
                  item={item}
                  selectedSidebarItem={selectedSidebarItem}
                  menuCollapsed={menuCollapsed}
                  gotoUrl={gotoUrl}
                  key={index}
                />
              ) : (
                <SidebarItemInsurance
                  item={item}
                  selectedSidebarItem={selectedSidebarItem}
                  menuCollapsed={menuCollapsed}
                  gotoUrl={gotoUrl}
                  key={index}
                />
              )
            ) : null
          )}
          {!Utils.getAccountData("userOnboard") && (
            <SidebarCustomMenuInsurance menuCollapsed={menuCollapsed} />
          )}
        </List>

        <div
          className="toggleButton"
          onClick={sidebarToggle}
          style={{ left: menuCollapsed ? "60px" : "172px" }}
        >
          {menuCollapsed ? (
            <SideNavLeftIcon
              style={{ transform: "rotate(180deg)" }}
              color="#fff"
            />
          ) : (
            <SideNavLeftIcon color="#fff" />
          )}
        </div>
      </div>
    </SidebarInsuranceWrapper>
  );
};

export default SidebarInsurance;
