import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { MenuItem} from '@material-ui/core';  

/* icons */
import icons from "../../assets/icons";
import { Person } from "@material-ui/icons";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import CustomSelect from "../../assets/CustomSelect";
import { getContactDetails, _updateStateField } from "../../redux/contactDetailAction";
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../assets/CustomAccordion";
import { getLeadSourceApi, updateContactSourceApi } from "../../redux/contactDetailApi";


const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer
});
const mapDispatchToProps = dispatch => ({
    _updateStateField: params => dispatch(_updateStateField(params)),
    getContactDetails: params => dispatch(getContactDetails(params))
})

const Source = (props) => {
    const { contactId, contactDetails } = props.contactDetailReducer
    const [expand, setExpand] = useState(false)
    const [leadSource, setLeadSource] = useState([])
    const [selectedLeadSource, setSelectedLeadSource] = useState('')
    const [sourceText, setSourceText] = useState('')
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        if(expand) {
            setSelectedLeadSource(contactDetails.source)
        }
    }, [expand])

    useEffect(() => {
        if(contactId != null){
            setSelectedLeadSource(contactDetails.source)
            getLeadSourceApi().then(res => {
                let response = res.data
                let list = response.leadSources
                response.leadSources.forEach((item, index) => {
                    list[index].value =  item.id.toString()
                })
                setLeadSource(list)
                const selected_list = response.leadSources.filter(item => item.id.toString() == contactDetails.source)
                if(selected_list.length > 0){
                    setSourceText(selected_list[0].source_title)
                }
            })
        }
    }, [contactId])

    const handleChange = (event) => {
        setSelectedLeadSource(event.target.value)
        setUpdating(true)
        let getSource = leadSource.filter(item => item.value == event.target.value)
        setSourceText(getSource[0].source_title)
        updateContactSourceApi({
            contact_id: contactId,
            source_id: event.target.value
        }).then(res => {
            setUpdating(false)
            setExpand(false)
        })
    }

    return(
        <div className="left__column columnItem">
            <CustomAccordion expand={expand} className={"collapsible defaultToggle trigger__agency"}>
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`collapsible-header trigger__header border__bottom flex content-between items-center ${expand ? 'expand' : ''}`}>
                <span className="trigger__subtitle"><Person /> Source </span>
                {(sourceText !== '' && !updating) &&
                <span>{sourceText}</span>
                }
                {(sourceText !== '' && updating) &&
                    <span><MoreHorizIcon fontSize="small"/></span>
                }
                {icons.caretIcon}
                </CustomAccordionHeader>
                <CustomAccordionBody className="collapsible-body">
                <ul className="templateItems source-container-contact-details">
                    <CustomSelect
                        id="lead-source-select"
                        value={selectedLeadSource}
                        handleChange={handleChange}
                        item={leadSource}
                        value_field={'value'}
                        titel_field={'source_title'}
                        placeholder={(<MenuItem value="" disabled> Select a source </MenuItem>)}
                    />
                </ul>
                </CustomAccordionBody>
            </CustomAccordion>
        </div> 
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Source);