import loginMiddleware from './loginMiddleware';
import signupMiddleware from './signupMiddleware';
import { all } from "redux-saga/effects";
import globalDialerMiddleware from "./globalDialerMiddleware";
import notificationMiddleware from './notificationMiddleware';
import tagMiddleware from './tagMiddleware';
import addContactMiddleware from '../components/addContact/addContactMiddleware';
import contactDetailMiddleware from "../components/contactDetails/redux/contactDetailMiddleware";
import videoEmailMiddleware from "../components/SendComponents/Video/redux/videoEmailMiddleware";
import sendModuleMiddleware from "./sendModuleMiddleware";
import cardBroadcastMiddleware from './inboxMiddleware';
import inboxMiddleware from '../components/DirectCardSend/redux/cardBroadcastMiddleware';
import contactMiddleware from "./contactMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            loginMiddleware(),
            signupMiddleware(),
            globalDialerMiddleware(),
            notificationMiddleware(),
            addContactMiddleware(),
            contactDetailMiddleware(),
            videoEmailMiddleware(),
            sendModuleMiddleware(),
            cardBroadcastMiddleware(),
            contactMiddleware(),
            tagMiddleware(),
            inboxMiddleware()
        ]
    )
}
