import { Button, styled, Typography } from "@material-ui/core";
import React from "react";
import {STEP_TABS} from "./constants";

const Wrapper = styled("div")(({ theme }) => ({
  minWidth: 500,
  minHeight: 250,

  "& .modalHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    borderBottom: "1px solid #d3d3d3",

    "& .titleWrapper": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .titleHeader": {
      fontSize: "20px",
    },
  },

  "& .modalBody": {
    padding: 16,
    textAlign: "center",

    "& .bodyContent": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },

  "& .actionButton": {
    backgroundColor: "#3E50F7",
    minWidth: 130,
    marginLeft: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#3E50F7",
    },
  },

  "& .closeButton": {
    minWidth: 130,
    borderColor: "#3E50F7",
    color: "#3E50F7"
  },

  "& .modalFooter": {
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const GetStarted = ({ setStep, onClose }) => {
  return (
    <Wrapper className="contentWrapper">
      <div className="modalHeader">
        <div className="titleWrapper">
          <img
            src="https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/1.0.0/phone-number-popup.png"
            height="50px"
            alt="logo"
          />
          <Typography className={"titleHeader"}>Phone Settings</Typography>
        </div>
      </div>

      <div className="modalBody">
        <div className="bodyContent">
          <div>
            <p>
              To enable the phone and texting functionality, <br /> you first
              need to add a phone number
            </p>
            <p style={{ fontWeight: 600 }}>
              Click the next button to get started
            </p>
          </div>
        </div>
      </div>

      <div className="modalFooter">
        <Button
            variant="outlined"
            color="primary"
            className={"closeButton"}
            disableElevation
            onClick={()=>{onClose()}}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={"actionButton"}
          disableElevation
          onClick={()=>{setStep(STEP_TABS.SELECT_NUMBER)}}
        >
          Next
        </Button>
      </div>
    </Wrapper>
  );
};

export default GetStarted;
