import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { searchVirtualNumberList } from "../../api/tenantApi";
import BasicInput from "../custom/BasicInput";
import BasicSelect from "../custom/BasicSelect";
import EachTableItem from "./EachTableItem";

const SelectNumberWrapper = styled("div")(({ theme }) => ({
  minWidth: 900,
  minHeight: 650,

  "& .modalHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    borderBottom: "1px solid #d3d3d3",
    "& .closeButton": {
      minWidth: 130,
      borderColor: "#3E50F7",
      color: "#3E50F7"
    },
    "& .titleWrapper": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .titleHeader": {
      fontSize: "20px",
    },
  },

  "& .modalBody": {
    padding: 16,

    "& .formLabel": {
      fontWeight: "600 !important",
      color: "#181f48",
      marginBottom: "0px !important",
    },
  },

  "& .actionButton": {
    backgroundColor: "#3E50F7",
    // minWidth: 130,
    "&:hover, &:focus": {
      backgroundColor: "#3E50F7",
    },
  },

  "& .noData": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "558px",

    "& span": {
      display: "block"
    }
  },

  "& .searching": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "558px",

    "& span": {
      display: "block"
    }
  },

  "& .footer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f7f7f7",
    borderRadius: "8px"
  }
}));

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#f3f3f3",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  tableBodyHeight: {
    height: `calc(100vh - 445px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
});

const CustomScrollbar = styled("div")({
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "10px",
    backgroundColor: "#2c3e50",
  },
  "&::-webkit-scrollbar-thumb": {
    // backgroundColor: "#706fd3",
    backgroundColor: "#346fef",
    borderRadius: "10px",
    // outline: '1px solid #8e44ad'
  },
});

const SelectNumber = ({ setStep, data, onClose }) => {
  const classes = useStyles();
  const [isSearched, setIsSearched] = useState(false);
  const [loading,setLoading] = useState(false);
  const [virtualNumbers, setVirtualNumbers] = useState([]);
  const [areaCode, setAreaCode] = useState("");
  const [country, setCountry] = useState("US");

  const handleSearchVirtualNumber = async () =>{
    try{
      setLoading(true);
      const response = await searchVirtualNumberList({ country, areaCode, page: 1, perPage: 30 });
      if (response.success){
        setVirtualNumbers(response.data);
      }
    }catch (err){
      setVirtualNumbers([]);
    }finally {
      setLoading(false);
      setIsSearched(true);
    }
  };

  return (
    <SelectNumberWrapper className="selectNumberWrapper">
      <div className="modalHeader">
        <div className="titleWrapper">
          <img
            src="https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/1.0.0/phone-number-popup.png"
            height="50px"
            alt="logo"
          />

          <div>
            <Typography className={"titleHeader"}>Phone Settings</Typography>
            <Typography variant="subtitle1" className={"subHeader"}>
              It's time to select a phone number. Follow the steps below
            </Typography>
          </div>
        </div>
        <Button
            variant="outlined"
            color="primary"
            className={"closeButton"}
            disableElevation
            onClick={()=>{onClose()}}
        >
          Close
        </Button>
      </div>

      <div className="modalBody">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <div>
                <Typography
                  variant="body1"
                  className={"formLabel"}
                  style={{ height: "auto" }}
                >
                  Select your country
                </Typography>
              </div>

              <BasicSelect
                fullWidth
                name="leadSource"
                options={[
                  {
                    name: "United States",
                    short: "US"
                  },
                  {
                    name: "Canada",
                    short: "CA"
                  }
                ]}
                defaultText="Select Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                mapping={{
                  label: "name",
                  value: "short",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <div>
                <Typography
                  variant="body1"
                  className={"formLabel"}
                  style={{ height: "auto" }}
                >
                  Preferred area code
                </Typography>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <BasicInput fullWidth value={areaCode} onChange={(e) => { setAreaCode(e.target.value) }} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={"actionButton"}
                    disableElevation
                    fullWidth
                    onClick={()=>{ handleSearchVirtualNumber() }}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <b>Note:</b> If your desired area code isn't available, please try
              again in 24 hours or select a different area code
            </Typography>
          </Grid>
        </Grid>

        {
          loading ?
              <div className="searching">
                <img src="https://d24gujj67p1uc1.cloudfront.net/assets/gifs/releases/1.0.0/searching.gif" alt="searching" height="300px"/>
                <span>Looking for phone numbers</span>
              </div> :
              virtualNumbers[0] ?
                  <Fragment>
                    <CustomScrollbar className={`${classes.tableBodyHeight}`}>
                      <TableContainer component={"span"}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead className={classes.tableHead}>
                            <TableRow>
                              <TableCell className={classes.tableHeader} width={"25%"}>
                                Phone Number
                              </TableCell>
                              <TableCell className={classes.tableHeader}>
                                Friendly Number
                              </TableCell>
                              <TableCell align={"center"} className={classes.tableHeader}>
                                Country
                              </TableCell>
                              <TableCell align={"center"} className={classes.tableHeader}>
                                Postal Code
                              </TableCell>
                              <TableCell align={"center"} className={classes.tableHeader}>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {
                              virtualNumbers.map((virtualNumber)=>(
                                  <EachTableItem
                                      item={virtualNumber}
                                      setStep={setStep}
                                      data={data}
                                  />
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomScrollbar>
                    <div className="footer">
                      <Button
                          variant="contained"
                          color="primary"
                          className={"actionButton"}
                          disableElevation
                          onClick={handleSearchVirtualNumber}
                      >
                        Refresh
                      </Button>
                    </div>
                  </Fragment> :
                  <div className="noData">
                    <img src="https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/1.0.0/virtual-number-tprnt.png" alt="no-data" height="300px"/>
                    <span>
                      {
                        isSearched ?
                            "Couldn't find phone numbers":
                            "Search for phone numbers"
                      }
                    </span>
                  </div>
        }
      </div>
    </SelectNumberWrapper>
  );
};

export default SelectNumber;
