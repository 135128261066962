import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import './sharePage.css';
import { ArrowBack, DeviceHub } from "@material-ui/icons";
import { connectGoogleProcessToken } from "../../api/notificationApi";
import { Button } from "@material-ui/core";
import NotFound from "../NotFound";

const isValidBase64 = (str) => {
    if (str.length % 4 !== 0) {
        return false;
    }

    const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    if (!base64Regex.test(str)) {
        return false;
    }

    try {
        let data = window.atob(str);

        return data.includes(":");
    } catch (e) {
        return false;
    }
}

const ConnectGoogleCallback = () => {
    const history = useHistory();
    const codeObj = useLocation().search;
    const code = new URLSearchParams(codeObj).get('code');
    const state = new URLSearchParams(codeObj).get('state');
    const [dots, setDots] = useState('');
    const [message, setMessage] = useState('Wait while request is being verified');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCloseAble, setIsCloseAble] = useState(false);
    const [backLink, setBackLink] = useState('/');

    useEffect(() => {
        if (isProcessing) {
            const intervalId = setInterval(() => {
                setDots(prevDots => prevDots.length < 3 ? prevDots + '.' : '');
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [isProcessing]);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        setIsProcessing(true);

        let campaignUid = state;
        let sheetState = "";

        if (isValidBase64(state)) {
            let decodedState = window.atob(state);
            let stateArray = decodedState.split(":");

            campaignUid = stateArray[1];
            sheetState = stateArray[0];
        }

        const resp = await connectGoogleProcessToken({ code: code, campaignUid: campaignUid });

        if (resp && resp.success && resp?.data?.user?.email && campaignUid && code) {
            setTimeout(() => {
                setIsProcessing(false);
                setIsCloseAble(false);
                setMessage('🎉 Congratulations! Request verified successfully');

                setTimeout(() => {
                    setMessage('Please wait while redirecting...');

                    setTimeout(() => {
                        let email = resp.data.user.email;
                        let domain = resp.data.domain;

                        let redirectUrl = 'https://' + domain + '/bulk-email?campaignUid=' + campaignUid + '&spreadsheet=' + email;

                        if (sheetState) {
                            redirectUrl += '&state=' + sheetState;
                        }

                        window.open(redirectUrl, '_self');
                    }, 1000);
                }, 2000);
            }, 3000);
        } else {
            setIsProcessing(false);
            setIsCloseAble(true);
            setMessage(resp?.message || 'Sorry! Unable to verify the request. Please try again later!');
            setBackLink('/bulk-email?campaignUid=' + state + '&error');
        }
    }

    if (!code || !state) {
        return <NotFound />;
    }

    return (
        <div className="share_page__wrapper trigger_share" style={{ height: "100vh" }}>
            <div className="share_page__inner_wrapper">
                <div className="nylas_connect">
                    <DeviceHub />
                </div>
                <h4>Connect Google</h4>
                {
                    <h5 className="mt-4">{message}
                        {
                            isProcessing ?
                                <span style={{ position: 'absolute' }}>{dots}</span> :
                                ''
                        }
                    </h5>
                }
                {
                    !isCloseAble ?
                        <p>Please do not close the tab or change the page</p> :
                        <p>You can close the tab and try again later</p>
                }

                {
                    isCloseAble &&
                    <Button variant={'contained'} color={'primary'} startIcon={<ArrowBack />} onClick={() => { history.push(backLink) }}>
                        back to previous page
                    </Button>
                }
            </div>
        </div>
    );
}

export default ConnectGoogleCallback;