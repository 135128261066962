import React, {Fragment, useEffect} from "react";
import {IconButton, styled} from "@material-ui/core";
import {
  DollarIcon,
} from "../../GlobalAddDealModalV3/components/Icons";
import HeaderSearchBarInsurance from "./HeaderSearchBarInsurance";
import Utils from "../../../helpers/Utils";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentCredit} from "../../../actions/globalDialerAction";
import {useHistory} from "react-router-dom";
import HeaderAddPopover from "./HeaderAddPopover";
import HeaderProfilePopover from "./HeaderProfilePopover";
import HeaderNotificationPopover from "./HeaderNotificationPopover";
import {fetchNotificationCount} from "../../../actions/notificationAction";
import {CODE_SNIPPET} from "../../../constants/CoreConstants";
import {Userpilot} from "userpilot";
import HeaderGlobalDialerInsurance from "./HeaderGlobalDialerInsurance";
import HeaderInsuranceLoginToParent from "./HeaderInsuranceLoginToParent";
import { HEADER_COLORS } from "./constants";

const HeaderWrapper = styled("header")(({ theme }) => ({
  height: 80,
  backgroundColor: "var(--topbar-background)",

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 24,

  "& .leftSideWrapper": {
    height: "100%",
    width: "fit-content",

    display: "flex",
    alignItems: "center",
    gap: 30,

    boxSizing: "content-box",
    transition: "all 0.5s ease-in-out",

    "& .toggleCollapse": {
      cursor: "pointer",
    },

    "& .logo": {
      maxWidth: 151,
      maxHeight: 55,
      objectFit: "cover",
    },
  },

  "& .rightSideWrapper": {
    width: "100%",
    height: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 8,

    "& .actionButton": {
      border: "1.8px solid #e0e0e0",      
      backgroundColor: "#ffffff",
      borderRadius: 12,
      padding: "12px !important",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1) !important",
      "&:focus, &:hover": {
        backgroundColor: "#f4f5f6",
      },

      "& .balance": {
        display: "flex",
        alignItems: "center",
        gap: 8,

        "& .balanceAmount": {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          color: "#58cc8a",
          margin: 0,
        },
      },

      "&.profile": {
        padding: "4px 8px !important",

        "& .profileWrapper": {
          display: "flex",
          alignItems: "center",
          gap: 8,
        },

        "& .avatarImgWrapper": {
          display: "flex",
          "& .avatarImg": {
            width: 32,
            height: 32,
            objectFit: "cover",
            borderRadius: "50%",
          },

          "& .MuiAvatar-colorDefault": {
            backgroundColor: "#bdbdbd"
          }
        },

        "& .userInfo": {
          "& .title": {
            color: "#36454F",
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "20px",

            textAlign: "left",
            margin: 0,
          },
          "& .email": {
            color: "#36454F",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "16px",

            textAlign: "left",
            margin: 0,
          },
        },
      },
    },
  },
}));

const HeaderInsurance = ({ menuCollapsed, minimizedLogo, agencyLogo, avatar, userEmail, userName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentCredit } = useSelector((state) => (state.globalDialerReducer));

  useEffect(()=>{
    dispatch(getCurrentCredit());
    dispatch(fetchNotificationCount({}));

    Utils.loadSocket(Utils.getAccountData("userId"));

    if (process.env.NODE_ENV !== "development") {
      Utils.loadCodeSnippets(CODE_SNIPPET.AFTER_LOGIN, {
        agencyId: Utils.getAccountData("agencyId"),
        userId: Utils.getAccountData("userId"),
        email: Utils.getAccountData("email"),
        fullName: Utils.getAccountData("fullName"),
      });
    }

    if (Utils.getAccountData("agencyId") === 1) {
      Userpilot.identify(Utils.getAccountData("userId"), { name: Utils.getAccountData("fullName"), email: Utils.getAccountData("email") });
    }
  },[]);

  window.updateCurrentCredit= ()=>{
    dispatch(getCurrentCredit());
  };

  return (
    <HeaderWrapper className="insuranceHeader">
      <div className="leftSideWrapper">
        <img
          className="logo"
          src={menuCollapsed && minimizedLogo ? minimizedLogo : agencyLogo}
          alt="logo"
        />
      </div>
      <div style={{ width: "900px", marginLeft: "27px", marginRight: "10px" }}>
        {!Utils.getAccountData("isTemplateUser") &&
            !Utils.getAccountData("userOnboard") && (
          <HeaderSearchBarInsurance />
        )}
      </div>

      <div className="rightSideWrapper">
        <HeaderInsuranceLoginToParent />
        {
            !Utils.getAccountData("userOnboard") &&
            <Fragment>
              <HeaderGlobalDialerInsurance />
              <HeaderAddPopover />
              <HeaderNotificationPopover />
            </Fragment>
        }

        {
            Utils.getAccountData("userIsOwner") &&
            !Utils.getAccountData("userOnboard") &&
            <IconButton className="actionButton" onClick={()=>{ if(window.location.pathname !== "/billing") history.push("/billing"); }}>
              <div className="balance">
                <DollarIcon  color={HEADER_COLORS.DOLLER_ICON}/>{/*Dollar icon color green*/}
                <p className="balanceAmount">{currentCredit === undefined ? '...' : Number(currentCredit).toFixed(2)}</p>
              </div>
            </IconButton>
        }
        <HeaderProfilePopover
            avatar={avatar}
            userEmail={userEmail}
            userName={userName}
        />
      </div>
    </HeaderWrapper>
  );
};

export default HeaderInsurance;
