import React from "react";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

const SidebarItemInsurance = ({ menuCollapsed, gotoUrl, selectedSidebarItem, item: { module, icon: Icon, label, url, visible, sub_menus, target = undefined, state = null, search = null } }) => {
    return(
        <BootstrapTooltip
            title={menuCollapsed ? label : ""}
            arrow
            placement="right"
        >
            <ListItem
                button
                selected={selectedSidebarItem === module}
                onClick={() => gotoUrl(url, module, state, search, target)}
                className="sidebarItem"
            >
                <ListItemIcon>
                    <Icon color={"var(--sidebar-icon-color)"} />
                </ListItemIcon>
                <ListItemText primary={label} />
            </ListItem>
        </BootstrapTooltip>
    );
};

export default SidebarItemInsurance;