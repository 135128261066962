import { MenuItem } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from 'react';
import "../../assets/global__send_modal.css";
import _ApiList from '../api/ApiList';
import { OutSideConstants, withOutCustomsFields } from '../assets/_constants';
import _Helper from '../assets/_Helper';
import CustomMultipleSelect from '../assets/components/CustomMultipleSelect';
import CustomSelect from '../assets/components/CustomSelect';
import TimeSetting from '../assets/components/TimeSettings';
import '../assets/emailSend.css';
import '../assets/smsSend.css';

const EmailSend = props => {
    /* ref start */
    const fromEmailRef = useRef(null)
    const subjectInputRef = useRef(null)
    const messageInputRef = useRef(null)
    /* ref end */

    /* state start */
    const [states, setStates] = useState({
        /* to email state */
        addCc: false,
        addBcc: false,
        textToAddCc: '',
        isCcValid: true,
        showCcValidMessage: '',
        textToAddBcc: '',
        isBccValid: true,
        showBccValidMessage: '',

        /* from email state */
        selectedFromEmail: '',
        fromEmail: '',
        nylasActive: false,

        /* contact information state */
        agency: null,
        userInfo: null,
        userSignature: null,
        appointmentUrl: null,

        /* email template state */
        showTemplates: false,
        saveAsTemplate: false,

        /* subject & message state */
        subject: '',
        validSubject: false,
        message: '',
        validMessage: false,
        attachment: null,

        /* time setting state */
        sendTime: 1, //2
        date: new Date(),
        time: null,
        isValidTime: true,

        /* more state */
        isLoading: true,
        type: 1, //for email type send
        sending: false,
        invalidSendTry: false,
        wentWrong: false
    })
    const [reRender, setReRender] = useState(false)
    const [toMails, setToMails] = useState([])
    const [selectedToMails, setSelectedToMails] = useState([])
    const [defaultCcMail, setDefaultCcMail] = useState([])
    const [ccMails, setCcMails] = useState([])
    const [bccMails, setBccMails] = useState([])
    const [fromEmails, setFromEmails] = useState([])
    const [personalizedTag, setPersonalizedTag] = useState([])


    /* state end */

    /* useEffect start */
    useEffect(() => {
        getOtherEmail()

        _ApiList.getPersonalizedTag().then(res => {
            let data = [];
            let customFields = [];
            res.data.data.forEach((row) => {
                customFields.push({
                    label: row.title,
                    value: row.personalize_tag,
                });
            });
            data = withOutCustomsFields.concat(customFields);

            let personalizedFields = [];
            personalizedFields.push({
                value: 'blue',
                label: 'User Customize Fields',
                color: '#cdcdcd',
                isDisabled: true
            });
            res.data.personalizedData.forEach((row) => {
                personalizedFields.push({
                    label: row.title,
                    value: row.personalize_tag,
                });
            });
            data = data.concat(personalizedFields);
            setPersonalizedTag(data)
        })
    }, [])

    const getOtherEmail = () => {
        _ApiList.getOtherEmailForAContact({ contact_id: props.contact_id }).then(res => {
            let response = res.data;

            if (response.length == 0 && _Helper.isEmpty(props.primary_email)) {
                handleStateChange({
                    invalidSendTry: true,
                    isLoading: false
                })
                return
            }

            let toEmailsData = []
            if (!_Helper.isEmpty(props.primary_email)) {
                toEmailsData = [{
                    email: props.primary_email, label: (props.primary_email + " (Primary Email)")
                }]
            }

            let selectedEmail = [...toEmailsData];
            if (response.length > 0) {
                response.forEach((eachMail, index) => {
                    toEmailsData.push({
                        id: eachMail.id,
                        email: eachMail.email,
                        label: eachMail.email + " (" + eachMail.contact_relation.relation_type + ")"
                    })
                    if (eachMail.is_default == 1) {
                        selectedEmail = [{
                            id: eachMail.id,
                            email: eachMail.email,
                            label: eachMail.email + " (" + eachMail.contact_relation.relation_type + ")"
                        }];
                    }
                })
            }
            setToMails(toEmailsData)
            setSelectedToMails(selectedEmail)
            getProfileData()
        }).catch((error) => {
            handleStateChange({
                isLoading: false,
                wentWrong: true
            })
        })
    }

    const getProfileData = () => {
        _ApiList.getProfileData().then(res => {

            let response = res.data
            let selectedFromEmail = ''
            if (response.campaign.length > 0) {
                selectedFromEmail = response.campaign[0];
            }
            if (response.agency.email_provider !== 0 && response.profile.email_provider === 1) {
                selectedFromEmail = {
                    label: JSON.parse(response.profile.nylas_email_provider_info).email,
                    value: JSON.parse(response.profile.nylas_email_provider_info).email,
                };
            }

            handleStateChange({
                selectedFromEmail: selectedFromEmail,
                nylasActive: response.profile.email_provider === 1 ? true : false,
                agency: response.agency,
                userInfo: response.profile,
                userSignature: response.profile.show_signature == 1 ? response.profile.signature : '',
                appointmentUrl: response.profile.show_appointment_url == 1 ? response.profile.appointment_url : '',
            })

            setFromEmails(response.campaign)

            let ccMails = []
            ccMails.push(response.profile.email)
            setDefaultCcMail(ccMails)
            setCcMails(ccMails)
            handleStateChange({
                isLoading: false,
            })
        }).catch(error => {
            handleStateChange({
                isLoading: false,
                wentWrong: true
            })
        })



    }

    // call api to get contact information
    // get other email information


    /* useEffect end */

    /* handle functions start */
    const handleStateChange = (objectData) => {
        setStates({ ...states, ...objectData })
        setReRender(!reRender)
    }

    /* cc mail */
    const addCcMail = (e) => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) {
            if (e.target.value === '') {
                handleStateChange({
                    isCcValid: false,
                    showCcValidMessage: 'Enter a mail address to add CC'
                })
            }
            else if (!_Helper.validateEmail(e.target.value)) {
                handleStateChange({
                    isCcValid: false,
                    showCcValidMessage: 'Enter a valid mail address to add CC'
                })
            }
            else if (ccMails.includes(e.target.value)) {
                handleStateChange({
                    isCcValid: false,
                    showCcValidMessage: 'This mail already added in CC'
                })
            }
            else {
                let newData = [...ccMails];
                newData.push(e.target.value)
                setCcMails(newData)
                handleStateChange({
                    textToAddCc: ''
                })
            }
        }
    }

    const addCcMailChange = (event) => {
        handleStateChange({
            textToAddCc: event.target.value,
            isCcValid: true,
            showCcValidMessage: ''
        })
    }

    const removeFromCc = (item, index) => {
        const oldData = [...ccMails];
        oldData.splice(index, 1)
        setCcMails(oldData)
    }

    const clearCcMailView = () => {
        handleStateChange({
            addCc: false
        })
        let data = []
        data = [...defaultCcMail]
        setCcMails(data)
    }

    /* bcc mail */
    const addBccMail = (e) => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) {
            if (e.target.value === '') {
                handleStateChange({
                    isBccValid: false,
                    showBccValidMessage: 'Enter a mail address to add BCC'
                })
            }
            else if (!_Helper.validateEmail(e.target.value)) {
                handleStateChange({
                    isBccValid: false,
                    showBccValidMessage: 'Enter a valid mail address to add BCC'
                })
            }
            else if (bccMails.includes(e.target.value)) {
                handleStateChange({
                    isBccValid: false,
                    showBccValidMessage: 'This mail already added in BCC'
                })
            }
            else {
                let newData = [...bccMails];
                newData.push(e.target.value)
                setBccMails(newData)
                handleStateChange({
                    textToAddBcc: ''
                })
            }
        }
    }

    const addBccMailChange = (event) => {
        handleStateChange({
            textToAddBcc: event.target.value,
            isBccValid: true,
            showBccValidMessage: ''
        })
    }

    const removeFromBcc = (item, index) => {
        const oldData = [...bccMails];
        oldData.splice(index, 1)
        setBccMails(oldData)
    }

    const clearBccMailView = () => {
        handleStateChange({
            addBcc: false
        })
        setBccMails([])
    }

    // subject field
    const handleSubjectPersonalize = (event) => {
        let start = subjectInputRef.current.selectionStart;
        let end = subjectInputRef.current.selectionEnd;
        let text = subjectInputRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event.target.value + after);
        // setSubject(newText);
        handleStateChange({
            subject: newText
        })
        subjectInputRef.current.selectionStart = subjectInputRef.current.selectionEnd = start + newText.length;
        subjectInputRef.current.focus();
    }

    // message field
    const handleMessagePersonalize = (event) => {
        handleStateChange({
            message: (states.message + event.target.value)
        })
        messageInputRef.current.focus();
    }

    const handleTimesetting = (type, value) => {
        if (type === 'date') {
            handleStateChange({ date: value })
        }
        else if (type === 'time') {
            handleStateChange({ time: value })
        }
        else if (type === 'tab') {
            handleStateChange({ sendTime: value })
        }
    }
    /* handle functions end */

    /* submit function start */
    const handleSubmit = () => {
        if (OutSideConstants.starterPack) {
            window.showNotification('ERROR', 'Sorry!!!, To use this feature please upgrade your package');
            return false;
        }

        /* check validation */
        if (states.sending) {
            return
        }
        if (selectedToMails.length == 0) {
            window.showNotification('ERROR', "No to email selected!");
            return
        }

        if (states.selectedFromEmail === '') {
            window.showNotification("ERROR", "No from email selected!")
            return
        }

        if (states.subject === '') {
            subjectInputRef.current.scrollIntoView({ behavior: 'smooth' })
            handleStateChange({ validSubject: true })
            return
        }

        if (states.message === '') {
            messageInputRef.current.focus()
            handleStateChange({ validMessage: true })
            return
        }

        if (states.sendTime == '2') {
            if (states.time === null) {
                handleStateChange({ isValidTime: false })
                window.showNotification("ERROR", "Select date time for schedule message", 'ERROR')
                return;
            }
        }

        let formData = {
            messages: states.message,
            message_type: 4,
            form: states.selectedFromEmail.value,
            subject: states.subject,
            type: 1,
            checked: states.saveAsTemplate,
            contact_id: props.contact_id,
            contact_emails: selectedToMails,
            schedule_type: parseInt(states.sendTime),
            date: states.sendTime == '2' ? states.date : null,
            time: states.time,
            origin: 6,
            cc: (states.addCc === false) ? [] : ccMails.join(', ') || 'empty',
            bcc: (states.addBcc === false) ? [] : bccMails.join(', ') || 'empty',
        }
        handleStateChange({ sending: true })

        _ApiList.submitSendEmailDefault(formData).then(res => {
            let response = res.data
            handleStateChange({ sending: false })
            if (response.status === "error") {
                window.showNotification('ERROR', response.html)
            }
            else {
                window.showNotification('SUCCESS', response.html)
                if (props.onClose !== undefined) {
                    props.onClose()
                }
                if (props.onSubmit !== undefined) {
                    props.onSubmit()
                }
            }
        })
    }
    /* submit function end */

    /* render functions start */
    const handleRender = () => {
        if (states.invalidSendTry) {
            return (
                <div className="invalid-try">
                    <p>No email address found for this contact. You can not send email for this contact.</p>
                    {/* <div>Back</div> */}
                </div>
            )
        }
        else if (states.wentWrong) {
            return (
                <div className="invalid-try">
                    <p>Something went wrong. Try again later</p>
                    {/* <div>Back</div> */}
                </div>
            )
        }
        return renderMain()
    }

    const renderTimeSetting = () => {
        if (!props.showTimeSetting) {
            return null;
        }
        return (
            <div className="gloabal-send-module-time-setting-container">
                {/* <TimeSetting
                    isValidTime={(!isValidTime && time == null)}
                    timeValidationMessage={'Date and Time is required'}
                    handleTimesetting={handleTimesetting}
                /> */}
                <TimeSetting
                    isValidTime={(!states.isValidTime && states.time == null)}
                    timeValidationMessage={'Date and Time is required'}
                    handleTimesetting={handleTimesetting}
                />
            </div>
        )
    }

    const apiKeys = () => {

        return "f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x";
    }

    const renderMain = () => {
        const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;
        return (
            <>
                <div className="d-flex align-items-center ds__row ">
                    <h3 className="trigger__modal_title ds__alt ds__email_to_cc_bcc send_mail_to m-0">To</h3>
                    <CustomMultipleSelect
                        className="send_email_select v2__send_email_select"
                        list={toMails}
                        value={selectedToMails}
                        placeholder={'Select a mail address'}
                        onChange={(event) => {
                            setSelectedToMails(event.target.value)
                        }}
                    />

                    {/* {!states.addCc &&
                <span className="ds__add ds__add_css common__round_circle send_add_cc" onClick={() => handleStateChange({addCc: true})}>Add CC</span>
                }
                {!states.addBcc && 
                <span className="ds__add ds__add_bcc common__round_circle send_add_bcc" onClick={() => handleStateChange({addBcc: true})}>Add BCC</span> 
                } */}
                </div>

                {states.addCc &&
                    <div className="d-flex align-items-center ds__row ds__cc_wrapper common__round_circle">
                        <h3 className="trigger__modal_title ds__alt ds__email_to_cc_bcc">Cc</h3>
                        <div className="d-flex align-items-start flex-wrap customColumns">
                            {ccMails.map((item, index) => {
                                return (
                                    <div className="ds__singleItem common__round_circle ds__item" key={item + "-" + index}>
                                        <span>{item} </span>
                                        <span onClick={() => removeFromCc(item, index)}>&times;</span>
                                    </div>
                                )
                            })}

                            <input className="trigger__modal_textField dealValue setFullWidth" type="text" onKeyPress={addCcMail} onChange={addCcMailChange} value={states.textToAddCc} placeholder="Add cc mail address" />
                            {!states.isCcValid &&
                                <span className="noValid">{states.showCcValidMessage}</span>
                            }
                            <span className="clear_all common__round_circle" onClick={clearCcMailView}>Clear All</span>
                        </div>
                    </div>
                }

                {states.addBcc &&
                    <div className="d-flex align-items-center ds__row ds__cc_wrapper common__round_circle ds__mt20pxSpace">
                        <h3 className="trigger__modal_title ds__alt ds__email_to_cc_bcc">Bcc</h3>
                        <div className="d-flex align-items-start flex-wrap customColumns">
                            {bccMails.map((item, index) => {
                                return (
                                    <div className="ds__singleItem common__round_circle ds__item" key={item + "-" + index}>
                                        <span>{item} </span>
                                        <span onClick={() => removeFromBcc(item, index)}>&times;</span>
                                    </div>
                                )
                            })}

                            <input className="trigger__modal_textField dealValue setFullWidth" type="text" onKeyPress={addBccMail} onChange={addBccMailChange} value={states.textToAddBcc} placeholder="Add bcc mail address" />
                            {!states.isBccValid &&
                                <span className="noValid">{states.showBccValidMessage}</span>
                            }
                            <span className="clear_all common__round_circle" onClick={clearBccMailView}>Clear All</span>
                        </div>
                    </div>
                }

                <div class="d-flex align-items-center ds__row  ds__mt20pxSpace" ref={fromEmailRef}>
                    <h3 className="trigger__modal_title send_email_from unset-flex">From : </h3>
                    <CustomSelect
                        formControlClass="send_email_from_select"
                        value={states.selectedFromEmail}
                        handleChange={(event) => handleStateChange({ selectedFromEmail: event.target.value })}
                        placeholder={(<MenuItem value="" disabled> Select a from email </MenuItem>)}
                        item={fromEmails}
                        titel_field={'label'}
                        disabled={states.nylasActive}
                        renderValue={(selected) => {
                            if (selected === '') {
                                return <em>Select a from email</em>;
                            }
                            return selected.label
                        }}
                    />
                    {/* <span style={{color: 'red', fontSize:'13px'}}>From email is required !</span> */}
                </div>

                <div className="ds__system_defined ds__alt1 personalized_select_content subject-container-alt">
                    {/* <CustomField handlePersonalize={handleSubjectPersonalize}/> */}
                    <input type="text" className="mr-2 border-none" placeholder="Enter mail subject" value={states.subject} onChange={(event) => handleStateChange({ subject: event.target.value })} ref={subjectInputRef} />
                    <CustomSelect
                        formControlClass=""
                        id='gloabal-email-send-personalized-selector-subject'
                        value={''}
                        handleChange={handleSubjectPersonalize}
                        placeholder={(<MenuItem value="" disabled>Personalized</MenuItem>)}
                        item={personalizedTag}
                        value_field={'value'}
                        titel_field={'label'}
                        customClass={'personalized-select-subject'}
                        checkDisabledValue={true}
                        disabledIndex={'isDisabled'}
                    />
                    {(states.validSubject && states.subject === '') &&
                        <span style={{ color: 'red' }}>Subject is required!</span>
                    }
                </div>

                <div className="ds__mt20pxSpace ds__selects_wrapper">
                    <div className="d-flex align-items-center send_email_personalizes">
                        <CustomSelect
                            formControlClass=""
                            id='gloabal-email-send-personalized-selector-message'
                            value={''}
                            handleChange={handleMessagePersonalize}
                            placeholder={(<MenuItem value="" disabled>Personalized</MenuItem>)}
                            item={personalizedTag}
                            value_field={'value'}
                            titel_field={'label'}
                            customClass={'personalized-select-message'}
                            checkDisabledValue={true}
                            disabledIndex={'isDisabled'}
                        />
                        {/* <span className="ds__template" onClick={() => handleStateChange({showTemplates: true})}>Used Templates</span> */}
                    </div>

                    <Editor
                        apiKey={apiKeys()}
                        onInit={(evt, editor) => messageInputRef.current = editor}
                        value={states.message}
                        initialValue={states.message}
                        cloudChannel='5-dev'
                        textareaName='email_reply'
                        init={{
                            height: 500,
                            browser_spellcheck: true,
                            // menubar: false,
                            menu: {
                                favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                            },
                            menubar: 'favs file edit view insert format tools table help',
                            plugins: [
                                'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                                'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                                'insertdatetime media table contextmenu paste code help wordcount template'
                            ],
                            convert_urls: false,
                            toolbar: `
                                insertfile undo redo | styleselect | bold italic backcolor |
                                alignleft aligncenter alignright alignjustify | link image |
                                bullist numlist outdent indent | removeformat | print preview media fullpage | help
                            `,
                            imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                            imagetools_proxy: emailUploadFileApi,
                            // images_upload_url: emailUploadFileApi,
                            // images_upload_credentials: true,
                            // images_upload_base_path: baseUrl,
                            images_upload_handler: _ApiList.image_upload_handler,
                            automatic_uploads: true,
                            file_picker_types: 'file image media',
                            forced_root_block: false
                        }}
                        onEditorChange={(value) => handleStateChange({ message: value })}
                        id="email-setting-body-input-field"
                        placeholder="Compose your mail message"
                    />
                    {(states.validMessage && states.message === '') &&
                        <span style={{ color: 'red' }}>Email message is required!</span>
                    }
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={<Checkbox checked={states.saveAsTemplate} onChange={() => handleStateChange({ saveAsTemplate: true })} name="save-as-template" />}
                            label="Save as a Quick Reply"
                        />
                    </FormControl>
                </div>

                {renderTimeSetting()}

                <div>
                    <div className="email_send_btn accent--bg--color" style={{ marginTop: 10 }} onClick={handleSubmit}>{states.sending ? 'Sending' : 'Send'}</div>
                </div>
            </>
        )
    }
    /* render functions end */

    return (
        <div className="global-email-send-module-container send_mail_modal global__send_modal_wrapper awesome__scroll_bar">
            {states.isLoading ?
                <div className="loader">Please wait</div>
                :
                handleRender()
            }

        </div>
    )
}
export default EmailSend;


/* 
contact_id: required if from is not 'map'
primary_email: required, empty data can be passed


*/
